import * as React from 'react';
import { Icon, IconWrapper, NameWrapper, PersistantHeaderWrapper, SocialLinksWrapper, Subtitle, SubtitleLine, Wrapper } from './PersistantHeaderStyles';
import { language } from './PersistantHeaderLanguage';
import FontAwesome from 'react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@mui/material';
import { useMobile } from '../../utils/useMobile';

export type Props = {};

export const PersistantHeaderLayout = (props: Props) => {
	const isMobile = useMobile();
	const title = isMobile ? language.titleMobile : language.title;
	const subtitle = isMobile ? language.subtitleMobile : null;
	const render = () => {
		const returnValue = (
			<PersistantHeaderWrapper>
				<Wrapper>
					<NameWrapper>{language.myName}</NameWrapper>
					<Subtitle>
						<SubtitleLine>{title}</SubtitleLine>
						<SubtitleLine>{subtitle}</SubtitleLine>
					</Subtitle>
					<SocialLinksWrapper>
						<IconWrapper>
							<Icon href="https://www.linkedin.com/in/fahirani/">
								<FontAwesomeIcon icon={['fab', 'linkedin-in']} />
							</Icon>
						</IconWrapper>
						<IconWrapper>
							<Icon href="https://www.faizhirani.com/cv/">
								<FontAwesomeIcon icon={['fas', 'file-user']} />
							</Icon>
						</IconWrapper>
					</SocialLinksWrapper>
				</Wrapper>
			</PersistantHeaderWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
