import * as React from 'react';
import { SitemapLayoutWrapper, Title, Content } from './SitemapStyles';
import { SitemapCard } from './helpers/SitemapCard';
const packageJSON = require('../../../package.json');

export type Props = {
	schema: any;
};

export const SitemapLayout = (props: Props) => {
	const render = (props: Props) => {
		let innerContent: any = [];
		// const keys = Object.keys(props.schema);
		props.schema.forEach((item: {}, index: number) => {
			const cardProps = {
				key: 'card_' + index,
				card: item,
			};
			const card = <SitemapCard {...cardProps} />;
			innerContent.push(card);
		});
		const returnValue = (
			<SitemapLayoutWrapper>
				<Title>Sitemap (v. {packageJSON.version})</Title>
				<Content>{innerContent}</Content>
			</SitemapLayoutWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render(props);
	return outerReturnValue;
};
