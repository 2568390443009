import * as React from 'react';
import { BulletListAndIntroWrapper, BulletListWrapper, IntroWrapper } from './BulletListStyles';
import { generateBulletPoints, PointType, Props as GenerateBulletListProps } from './helpers/generateBulletPoints';

export type Props = {
	intro: string;
	points: Array<PointType>;
	delimiter: string;
	terminator: string;
	lastConnector?: string;
	style?: any;
};

export const BulletListLayout = (props: Props) => {
	const render = () => {
		const generateProps: GenerateBulletListProps = {
			points: props.points,
			delimiter: props.delimiter,
			terminator: props.terminator,
			lastConnector: props.lastConnector ? props.lastConnector : null,
		};
		const style = props.style ? props.style : {};
		const returnValue = (
			<BulletListAndIntroWrapper style={style}>
				<IntroWrapper>{props.intro}</IntroWrapper>
				<BulletListWrapper>{generateBulletPoints(generateProps)}</BulletListWrapper>
			</BulletListAndIntroWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
