import {ConstActionTypes} from './actionTypes';
import {ConstantsActions} from './actions';

type Dispatch = (action: ConstActionTypes) => void;

export const setWindowWidth = (width: number) => async (dispatch: Dispatch) =>
{
	dispatch(ConstantsActions.setWindowWidth(width));
};

export const setWindowHeight = (height: number) => async (dispatch: Dispatch) =>
{
	dispatch(ConstantsActions.setWindowHeight(height));
};

export const setDevMode = (devModeOn: boolean) => async (dispatch: Dispatch) =>
{
	dispatch(ConstantsActions.setDevMode(devModeOn));
};

export const setLayoutType = (layoutType: string) => async (dispatch: Dispatch) =>
{
	dispatch(ConstantsActions.setLayoutType(layoutType));
};

export const setLoading = (loading: boolean) => async (dispatch: Dispatch) =>
{
	dispatch(ConstantsActions.setLoading(loading));
};

export const setBookingMode = (bookingMode: boolean) => async (dispatch: Dispatch) =>
{
	dispatch(ConstantsActions.setBookingMode(bookingMode));
};
