import { UseCaseType } from '../../../Types/Portfolio/UseCaseType';
import { CommunityLegalServices } from '../Employment/Community Legal Services';

export const useCase: UseCaseType = {
	title: '#Time Matters',
	image: 'https://assets.dear-ux.com/faizhirani/images/Chowbotics Architecture.png',
	employment: CommunityLegalServices,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ['At Community Legal Services, as part of legal file work, students were required to log time, and document work done.', 'Due to law society requirements (or so we were told), the long standing process was as follows:', ['Create record Open closed system docketing software (Time Matters);', 'Log time;', 'Write see Memo To File (MTF) in the description box;', 'Open MTF template file in Microsoft Word;', 'Fill out template details (date, your name, client name, etc);', 'Document work that was done in the template;', 'Save MTF to network drive with standardized name (date-clientName-mtf.doc);', 'Print out MTF and Time Matters time log record;', 'Staple together and put in file.'], 'This ~3 minute process was fraught with opportunities for human error that would overwrite existing word documents. As a Litigation Practice student, I held 3 legal files during the semester and this wasted ~45 minutes / week. I smirked at the inefficiency, but tolerated by it as many sheep had done before.', 'After being hired as a Student Supervisor, during the summer 12 of us staffed the clinic, and each held 20+ files. In this new capacity, this process was undertaken  ~20-40 times a day, and wasted between 6 and 10 hours a week for each of the 12 of us.', 'The tedium I felt, and the tedium I witnessed, drove me to act.'],
		},
		{
			sectionTitle: 'Solution',
			paras: ['Time Matters was not a well documented piece of software. It had a reporting engine in a markup language that was meant to create the types of monthly reports expected at a law firm.', 'I spent a weekend experimenting and learning the intricacies of Time Matters. By Monday, I had replicated the MTF format using the notes input box in Time Matters.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['Come Monday morning, no one knew of my attempt or achievement. I advised the first person who I saw logging time in the usual way to click a small generate button that I had put at the top of the screen.', 'The discovery was with astonishment, disbelief and squeels of excitement. All 11 of my peers gathered around the computer in wonder as everyone instantly understood the impact of the new process. This action instantly cut about 8 hours of monotenous busy work from our plates every week. The impact on morale was great.', 'The townspeople danced in the streets to celebrate the victory.'],
		},
	],
};
