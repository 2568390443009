import { ToolsType } from '../../../Types/ToolsType';
import { TechnologyType } from '../../../Types/TechnologyType';

export type SchemaType = {
	title: string;
	lines: Array<TechnologyType>;
};

export const schema: SchemaType = {
	title: 'Technologies',
	lines: [
		// Architecture & Cloud
		{
			entry: 'Distributed Architecture',
			showOn: ['all'],
		},
		{
			entry: 'Event-Driven Architecture',
			showOn: ['all'],
		},
		{
			entry: 'RESTful APIs',
			showOn: ['all'],
		},
		{
			entry: 'Google Cloud Functions',
			showOn: ['google'],
		},
		{
			entry: 'AWS Lambda',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Functions',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Pub/Sub',
			showOn: ['google'],
		},
		{
			entry: 'AWS SNS',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Service Bus',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Tasks',
			showOn: ['google'],
		},
		{
			entry: 'AWS SQS',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Queue Storage',
			showOn: ['azure'],
		},
		{
			entry: 'Google Compute Engine',
			showOn: ['google'],
		},
		{
			entry: 'AWS EC2',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Virtual Machines',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud CDN',
			showOn: ['google'],
		},
		{
			entry: 'AWS CloudFront',
			showOn: ['aws'],
		},
		{
			entry: 'Azure CDN',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Firestore',
			showOn: ['google'],
		},
		{
			entry: 'AWS DynamoDB',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Cosmos DB',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud SQL',
			showOn: ['google'],
		},
		{
			entry: 'AWS RDS',
			showOn: ['aws'],
		},
		{
			entry: 'Azure SQL Database',
			showOn: ['azure'],
		},
		{
			entry: 'Google BigQuery',
			showOn: ['google'],
		},
		{
			entry: 'AWS Redshift',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Synapse Analytics',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Storage',
			showOn: ['google'],
		},
		{
			entry: 'AWS S3',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Blob Storage',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Build',
			showOn: ['google'],
		},
		{
			entry: 'AWS CodePipeline',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Pipelines',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Build',
			showOn: ['google'],
		},
		{
			entry: 'AWS CodeBuild',
			showOn: ['aws'],
		},
		{
			entry: 'Azure DevOps Build',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Source Repositories',
			showOn: ['google'],
		},
		{
			entry: 'AWS Code Commit',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Repos',
			showOn: ['azure'],
		},
		{
			entry: 'OpenAI',
			showOn: ['all'],
		},

		// Databases
		{
			entry: 'NoSQL Databases',
			showOn: ['all'],
		},
		{
			entry: 'SQL Databases',
			showOn: ['all'],
		},

		// Programming Languages & Frameworks
		{
			entry: 'NodeJS',
			showOn: ['all'],
		},
		{
			entry: 'Express',
			showOn: ['all'],
		},
		{
			entry: 'Django',
			showOn: ['all'],
		},
		{
			entry: 'Flask',
			showOn: ['all'],
		},
		{
			entry: 'PHP',
			showOn: ['all'],
		},
		{
			entry: 'C',
			showOn: ['all'],
		},
		{
			entry: 'C++',
			showOn: ['all'],
		},
		{
			entry: 'Java',
			showOn: ['all'],
		},
		{
			entry: 'Bash Scripting',
			showOn: ['all'],
		},

		// Frontend & Full-Stack
		{
			entry: 'React JS',
			showOn: ['all'],
		},
		{
			entry: 'React Native',
			showOn: ['all'],
		},
		{
			entry: 'Angular',
			showOn: ['all'],
		},
		{
			entry: 'Typescript',
			showOn: ['all'],
		},
	],
};
