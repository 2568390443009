import { SessionStoreType } from './storeTypes';

export const SessionDefaultStore: SessionStoreType = {
	action: 'sessionActionTaken',
	user: null,
	portal: "",
	session_id: '',
	session_start_date_time: '',
	session_activity: [],
	session_end_date_time: '',
	session_business_session: false,
};
