import {combineReducers} from 'redux';
import {SessionReducers} from "../app/user-sessions/duck";
import {ConstantsReducers} from "../app/consts/duck/reducers";
import {FiltersReducers} from "../app/filters/duck";
import {SnackbarReducers} from "../app/snackbar/duck";
import {InfoReducers} from "../app/info/duck";

export default combineReducers({
	session: SessionReducers,
	constants: ConstantsReducers,
	filters: FiltersReducers,
	snackbar: SnackbarReducers,
	info: InfoReducers
});
