import * as React from 'react';
import { Props as LayoutProps } from './PortfolioLayout';
import { useCases } from '../../Schema/Portfolio/useCases';
import { UseCaseType } from '../../Types/Portfolio/UseCaseType';
import { UseCaseWrapperType } from '../../Types/Portfolio/UseCaseWrapperType';

export type Props = {
	Layout: any;
};

export const PortfolioContainer = (props: Props) => {
	const render = () => {
		const Layout = props.Layout;
		const enhancedUseCases = generateUseCaseSections(useCases);
		const outgoingProps: LayoutProps = {
			useCases: enhancedUseCases,
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const generateUseCaseSections = (useCases: UseCaseWrapperType[]) => {
	const returnValue: { [key: string]: UseCaseType[] } = {};
	useCases.forEach((useCaseWrapper: UseCaseWrapperType) => {
		const company = useCaseWrapper.section;
		if (!(company in returnValue)) returnValue[company] = [];
		returnValue[company].push({ ...useCaseWrapper.content, id: useCaseWrapper.id });
	});
	return returnValue;
};
