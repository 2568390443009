import { UseCaseType } from '../../../Types/Portfolio/UseCaseType';
import { Chowbotics } from '../Employment/Chowbotics';

export const useCase: UseCaseType = {
	title: '#Language Abstraction',
	image: 'https://assets.dear-ux.com/faizhirani/images/Chowbotics Language.png',
	employment: Chowbotics,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ['I was hired at Chowbotics during a growth spurt initialized through securing Series A funding. In the software department there were two main objectives.', ['Overhauling the Ordering App that was used by consumers at the Robot Terminal', 'Overhauling the monolithic structure used to power that App, and used for monitoring and evaluation'], 'Each of these was reconstructed from the ground up. I took charge of the latter, and with it, I took charge of the information flow through the entire pipeline.', 'In that capacity, I exerted influence over the structure of the Ordering App. As would be expected, there were many strings throughout the App that communicated with the end users. I wanted to ensure that those strings were well organized.'],
		},
		{
			sectionTitle: 'Solution Implemented',
			paras: ['I suggested that language strings be abstracted from the markup layer of the Ordering App and kept in a layer of its own. That is, to write markup similar to:', '<ParagraphStyle>\n{language.contentString}\n</ParagraphStyle>', 'Then, in each component, the dedicated language file exported the required strings.', 'Despite the fact that no one saw the point, the software team implemented my structure as I was adamant, and it was such a simple thing to do.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['There were three phases of impact that this decision had.', 'In the first phase, the software team found App development ran smoother because of the increased organization. Developers also felt comfortable making changes to parts of the App with which they were not familiar.', 'The second phase was after initial launch. There was a constant stream of feedback that came from the Marketing, Customer Success, Operations, and Customer Service Departments, internal dogfooding sessions, and data analysis. Feedback was incorporated with no cognitive load.', 'The third phase was later and less expected. Having established a foothold in the market, Chowbotics landed a United Arab Emirates contract, and we had to enable users to choose between Farsi and English.', 'When this requirement was first communicated, the team found it an extremely intimidating task. I pointed out that we already had a language layer that should be easy to leverage.', "Rather than hunting down every in-App string, we easily abstracted the language layer to a NoSQL key-value DB, and created an Lambda service through which the App to retrieve the strings associated with the user's chosen language.", 'The software team was amazed that, with the abstracted language structure already in place, the entire App was translated within a couple of days.', "I wasn't.", 'This same structure was later leveraged to incorporate French and Spanish.'],
		},
	],
};
