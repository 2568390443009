import * as React from 'react';
import { HighlightLinkWrapper, HighlightWrapper } from '../HighlightsStyles';
import { language } from '../HighlightsLanguage';

export type Props = {
	highlight: { text: string; href?: string };
};

export const Highlight = (props: Props) => {
	const render = () => {
		let returnValue = <HighlightWrapper>{props.highlight.text}</HighlightWrapper>;
		if (props.highlight.href) {
			const outgoingProps = {
				href: props.highlight.href,
				target: '_blank',
			};
			returnValue = <HighlightLinkWrapper {...outgoingProps}>{props.highlight.text}</HighlightLinkWrapper>;
		}
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
