import * as React from 'react';
import { BackgroundTitleWrapper } from './BackgroundTitleStyles';

export type Props = {
	text: string;
	backgroundColor: string;
	textColor: string;
};

export const BackgroundTitleLayout = (props: Props) => {
	const style = {
		backgroundColor: props.backgroundColor,
		color: props.textColor,
	};
	const returnValue = <BackgroundTitleWrapper style={style}>{props.text}</BackgroundTitleWrapper>;
	return returnValue;
};
