const SESSION_ACTION = 'SESSION_ACTION';
const END_SESSION = 'END_SESSION';
const MARK_AS_BUSINESS_SESSION = 'MARK_AS_BUSINESS_SESSION';

export enum AvailableActions {
	NO_ACTION = 'No Action',
	BEGUN = 'Session started',
	APP_MENU_BUTTON = 'App Bar Menu Button Pressed',
	LEFT_MENU_BUTTON = 'Left Hand Menu Button Pressed',
	KEYSTROKE = 'Keystroke',
	END = 'Session Ended',
	BUTTON_PRESSED = 'Button Pressed',
	AREA_PRESSED = 'Area Pressed',
	ITEM_PRESSED = 'Item Pressed',
	UNPRESSABLE_PRESSED = "Unpressable Pressed",
	MENU_ITEM = 'Menu Item Pressed',
	FOCUS = 'Brought into Focus',
	OPTION_SELECTED = "Option Selected",
	REQUEST_BOOKING_BUTTON_PRESSED = "Request Booking Button Pressed",
	REQUEST_BOOKING_ITEM_PRESSED = 'Request Booking Item Pressed',
};

export type END_SESSION_ACTION = { type: typeof END_SESSION };
export type SESSION_ACTION_ACTION = {
	type: typeof SESSION_ACTION,
	payload: {
		timestamp: string,
		currentPage: string,
		action: AvailableActions,
		details: string,
	}
};
export type MARK_AS_BUSINESS_SESSION = { type: typeof MARK_AS_BUSINESS_SESSION };

export type SessionActionTypes = END_SESSION_ACTION | SESSION_ACTION_ACTION | MARK_AS_BUSINESS_SESSION;

export const ActionNames = {
	SESSION_ACTION,
	END_SESSION,
}
