import * as React from 'react';
import { ResultWrapper } from '../ProfessionalExperienceSectionStyles';

export type Props = {
	results: string | null;
};

export const ResultLayout = (props: Props) => {
	const render = () => {
		const resultJSX = <ResultWrapper>{props.results}</ResultWrapper>;
		const returnValue = props.results ? resultJSX : null;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
