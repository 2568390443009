import * as React from 'react';
import { LengthWrapper } from '../ProfessionalExperienceSectionStyles';

export type Props = {
	length: string | null;
};

export const LengthLayout = (props: Props) => {
	const render = () => {
		const returnValue = <LengthWrapper>{props.length}</LengthWrapper>;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
