import * as React from 'react';
import { DescriptionWrapper } from '../ProfessionalExperienceSectionStyles';

export type Props = {
	description: string;
};

export const DescriptionLayout = (props: Props) => {
	const render = () => {
		const description = <DescriptionWrapper>{props.description}</DescriptionWrapper>;
		const returnValue = props.description || props.description.length === 0 ? null : description;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
