import * as React from 'react';
import { ContactLineItemWrapper, IconWrapper, TextWrapper, LinkWrapper } from '../ContactSectionStyles';
import { language } from '../ContactSectionLanguage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconPrefix } from '@fortawesome/pro-regular-svg-icons';
import { IconName } from '@fortawesome/free-brands-svg-icons';

export type Props = {
	icon: string[];
	text: string;
	link?: string;
};

export const ContactLineItem = (props: Props) => {
	const render = () => {
		const textJSX = props.link ? <LinkWrapper href={props.link}>{props.text}</LinkWrapper> : <TextWrapper>{props.text}</TextWrapper>;
		const returnValue = (
			<ContactLineItemWrapper>
				<IconWrapper>
					<FontAwesomeIcon icon={[props.icon[0] as IconPrefix, props.icon[1] as IconName]} />
				</IconWrapper>
				{textJSX}
			</ContactLineItemWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
