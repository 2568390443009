import * as React from 'react';
import { MajorAccomplishmentWrapper } from '../ProfessionalExperienceSectionStyles';

export type Props = {
	majorAccomplishments: string | null;
};

export const MajorAccomplishmentLayout = (props: Props) => {
	const render = () => {
		const majorAccomplishmentJSX = <MajorAccomplishmentWrapper>{props.majorAccomplishments}</MajorAccomplishmentWrapper>;
		const returnValue = props.majorAccomplishments && props.majorAccomplishments.length > 0 ? majorAccomplishmentJSX : null;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
