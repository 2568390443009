import * as React from 'react';
import { EducationContentWrapper, EducationPanelWrapper } from './EducationPanelStyles';
import { language } from './EducationPanelLanguage';
import { EducationEntryType } from '../../Types/EducationType';
import { generateEducationItem, Props as GenerateProps } from './helpers/generateEducationItem';
import { SectionTitleLayout, Props as SectionTitleProps } from '../SectionTitle/SectionTitleLayout';

export type Props = {
	title: string;
	backgroundColor: string;
	textColor: string;
	lines: Array<EducationEntryType>;
};

export const EducationPanelLayout = (props: Props) => {
	const render = () => {
		const backgroundTitleProps: SectionTitleProps = {
			text: props.title,
		};
		const generateProps: GenerateProps = {
			lines: props.lines,
		};
		const returnValue = (
			<EducationPanelWrapper>
				<SectionTitleLayout {...backgroundTitleProps} />
				<EducationContentWrapper>{generateEducationItem(generateProps)}</EducationContentWrapper>
			</EducationPanelWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
