import { styled } from '@mui/material/styles';
import { mobileThreshold } from '../../../../Constants/layoutValues';

export const UseCasesWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	@media (max-width: ${mobileThreshold}px) {
		height: fit-content;
	} ;
`;

export const UseCaseSectionWrapper = styled('div')``;

export const UseCaseItemWrapper = styled('div')``;

export const UseCaseSectionTitle = styled('div')``;
