import * as React from 'react';
import { SectionTitleWrapper, SectionTitleCentered, SectionTitleFullWidth } from './SectionTitleStyles';
import { language } from './SectionTitleLanguage';

export type Props = {
	text: string;
	fullWidth?: boolean;
};

export const SectionTitleLayout = (props: Props) => {
	const render = () => {
		const SectionTitle = !props.fullWidth ? SectionTitleCentered : SectionTitleFullWidth;
		const returnValue = (
			<SectionTitleWrapper>
				<SectionTitle>{props.text}</SectionTitle>
			</SectionTitleWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
