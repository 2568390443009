import * as React from 'react';
import { language } from './FancyHeaderLanguage';
import { FancyHeaderWrapper, Subtitle, Title, Titles } from './FancyHeaderStyles';

export type Props = {};

export const FancyHeaderLayout = (props: Props) => {
	const render = () => {
		const returnValue = (
			<FancyHeaderWrapper>
				<Subtitle>{language.name}</Subtitle>
			</FancyHeaderWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
