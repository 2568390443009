import * as React from 'react';
import { language } from './PortfolioLanguage';
import { ContentWrapper, LaneOne, LaneTwo, PortfolioWrapper } from './PortfolioStyles';
import { GeneralViewPortLayout, Props as GeneralViewPortProps } from '../../Components/GeneralViewPort/GeneralViewPortLayout';
import { AboutMeLayout, Props as AboutMeProps } from './Layouts/AboutMe/AboutMeLayout';
import { UseCaseListingLayout, Props as UseCasesProps } from './Layouts/UseCaseListing/UseCaseListingLayout';
import { UseCaseType } from '../../Types/Portfolio/UseCaseType';
import { ContactSectionLayout } from './Layouts/ContactSection/ContactSectionLayout';
import { useMobile } from '../../utils/useMobile';
import { CoreLayout, Props as CoreProps } from './Layouts/Core/CoreLayout';
import { HighlightsLayout, Props as HighlightProps } from './Layouts/Highlights/HighlightsLayout';
import { VersionLayout } from '../../Components/Version/VersionLayout';
export type Props = {
	useCases: { [key: string]: UseCaseType[] };
};

export const PortfolioLayout = (props: Props) => {
	const isMobile = useMobile();
	const render = () => {
		const aboutMeProps: AboutMeProps = {};
		const useCasesProps: UseCasesProps = {
			useCaseSections: props.useCases,
		};
		const highlightProps: HighlightProps = {};
		const coreProps: CoreProps = {};
		const desktopJSX = (
			<PortfolioWrapper>
				<ContentWrapper>
					<LaneOne>
						<AboutMeLayout {...aboutMeProps} />
						<UseCaseListingLayout {...useCasesProps} />
					</LaneOne>
					<LaneTwo>
						<ContactSectionLayout />
						<CoreLayout {...coreProps} />
						<HighlightsLayout {...highlightProps} />
					</LaneTwo>
				</ContentWrapper>
				<VersionLayout />
			</PortfolioWrapper>
		);
		const mobileJSX = (
			<PortfolioWrapper>
				<ContentWrapper>
					<LaneTwo>
						<ContactSectionLayout />
					</LaneTwo>
					<LaneOne>
						<AboutMeLayout {...aboutMeProps} />
						<UseCaseListingLayout {...useCasesProps} />
					</LaneOne>
				</ContentWrapper>
				<VersionLayout />
			</PortfolioWrapper>
		);
		const outgoingProps: GeneralViewPortProps = {
			internalJSX: isMobile ? mobileJSX : desktopJSX,
		};
		const returnValue = <GeneralViewPortLayout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
