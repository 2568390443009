export const highlights = [
	{
		text: '15+ Years of Software Experience',
	},
	{
		text: 'Techstars Alumnus 2016',
	},
	{
		text: 'Featured in Forbes Magazine',
		href: 'https://www.forbes.com/sites/tobyshapshak/2016/02/10/kenyas-bamba-group-is-incubator-techstars-first-east-africa-investment/',
	},
	{
		text: "Featured on Lion's Den",
		href: 'https://www.youtube.com/watch?v=sXShhjNlbEk',
	},
	{
		text: 'Tech Contributed to DoorDash Acquisition',
		href: 'https://techcrunch.com/2021/02/08/doordash-acquires-salad-making-robotics-startup-chowbotics/',
	},

	{
		text: "Software for Dominion's First Voting Machine",
	},
];
