import { styled } from '@mui/material/styles';
import { cssVarColors } from '../../Constants';

export const VersionWrapper = styled('div')`
	position: absolute;
	bottom: 0;
	right: 0;
	padding-bottom: 0.5rem;
	font-size: 0.8rem;
	color: ${cssVarColors.surface};
	padding-right: 0.5rem;
`;
