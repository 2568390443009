import { InfoActionTypes } from './actionTypes';
import { InfoDefaultStore } from './store';
import { InfoStoreType } from './storeTypes';
import _ from 'lodash';

export const initialState = InfoDefaultStore;

export const InfoReducers = (state: InfoStoreType = initialState, action: InfoActionTypes): InfoStoreType => {
	switch (action.type) {
		case 'SET_AVAILABLE_CATEGORIES': {
			let newState = {
				...state,
				availableCategories: action.payload.availableCategories,
			};
			return newState;
		}
		case 'SET_LAST_UPDATED': {
			let newState = {
				...state,
				last_updated: action.payload.last_updated,
			};
			return newState;
		}
		case 'SET_LOADING': {
			let newState = {
				...state,
				loading: action.payload.loading,
			};
			return newState;
		}
		case 'SET_ERROR': {
			let newState = {
				...state,
				error: action.payload.error,
			};
			return newState;
		}
		default:
			return state;
	}
};
