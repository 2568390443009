import React from 'react';
import { RouteType } from '../types';
import { CvLayout } from '../../../Pages/CV/cvLayout';
import { CvContainer } from '../../../Pages/CV/cvContainer';

export const routes: Array<RouteType> = [
	{
		path: '/cv/',
		element: <CvContainer Layout={CvLayout} />,
	},
];
