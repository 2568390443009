import * as React from 'react';
import { QuoteWrapper } from './QuoteStyles';

export type Props = {
	text: string;
};

export const QuoteLayout = (props: Props) => {
	const render = () => {
		const returnValue = <QuoteWrapper>{props.text}</QuoteWrapper>;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
