import * as React from 'react';
import { ParagraphWrapper } from '../AboutMeStyles';
export type Props = {
	para: string;
};

export const Paragraph = (props: Props) => {
	const render = () => {
		const returnValue = <ParagraphWrapper>{props.para}</ParagraphWrapper>;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
