import { UseCaseType } from '../../../Types/Portfolio/UseCaseType';
import { Chowbotics } from '../Employment/Chowbotics';

export const useCase: UseCaseType = {
	title: '#Fleet Command',
	image: 'https://assets.dear-ux.com/faizhirani/images/Fleet Command.png',
	employment: Chowbotics,
	body: [
		{
			sectionTitle: 'Opportunity',
			paras: ["Following the introduction of <href='/portfolio/useCase?id=chowboticsNotificationStreams'>Notification Streams</href>, our Sally robots began emitting a significant number of events. Including a periodic heartbeat that reported various health metrics.", 'While the notifications allowed our Customer Success Managers (CSMs) to respond to exceptions, the lack of updates when everything was running smoothly led to some curiosity and questions from the team. During lunch breaks, employees would often ask the CSMs about the status of the Sallys in the field.', 'Recognizing an appetite for more detailed field information, I was inspired to create a solution that could satisfy this curiosity and provide real-time insights.'],
		},
		{
			sectionTitle: 'Solution Implemented',
			paras: ['In my free time, I developed a live dashboard called Fleet Command, which displayed real-time details about each Sally in the field. Key Performance Indicators (KPIs), such as current chamber temperature, last heartbeat time, and the number of sales made, were prominently displayed. Fleet Command also included color-coded alerts to indicate warnings and exceptions when health metrics were out of spec.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['Fleet Command was a resounding success.', 'As we were all familiar with the key performance indicators of our Sallys, there was no need for any explanation of what was being shown.', "Our CEO appreciated the ability to get a comprehensive overview of the day's operations at a glance.", 'The Customer Success team valued Fleet Command for its ability to display real-time KPIs, allowing them to react immediately to any potential issues. The collaborative environment improved as CSMs could confidently step away, knowing their colleagues could monitor and address any exceptions in their absence.', 'Fleet Command fostered a stronger connection between the office team and field operations. One of our Mechanical Engineers, known for his unique character, would often sit on the couch in front of the office TV, quietly watching Fleet Command during lunch.', 'Fleet Command also inspired further enhancements, leading to the addition of columns for:', ['Positive/negative user feedback counts', 'App version numbers', 'Firmware version numbers', 'Active ingredient canisters'], 'These additions enabled quicker issue resolution and better situational awareness.'],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ["Chowbotics' network infrastructure, which I designed for agility, was distributed, event-driven, and highly decoupled. With the Sallys already broadcasting a heartbeat and the architecture's decoupled nature, it was straightforward to partition a NoSQL table by Sally and store the most recent heartbeat information. Once the data was accessible, building the front end was a trivial task."],
		},
	],
};
