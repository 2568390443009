import styled from 'styled-components';

export const ComingSoonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	color: #fff;
	background-color: #000;
	min-height: 100vh;
	min-width: 100vw;
	font-size: 2rem;
`;
