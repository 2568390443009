import * as React from 'react';
import { BulletPointWrapper } from '../BulletListStyles';

export type PointType = string;

export type Props = {
	points: Array<PointType>;
	delimiter: string;
	terminator: string;
	secondLastTerminator?: string;
	lastConnector: string | null;
};

export const generateBulletPoints = (props: Props) => {
	const returnValue = props.points.map((point: PointType, index: number) => {
		const suffix = index === props.points.length - 1 ? props.terminator : props.delimiter;
		const lastConnector = index === props.points.length - 2 ? props.lastConnector : null;
		const innerReturnValue = (
			<BulletPointWrapper>
				{point}
				{suffix} {lastConnector}
			</BulletPointWrapper>
		);
		return innerReturnValue;
	});
	return returnValue;
};
