import * as React from 'react';
import { UseCasesWrapper } from './UseCaseListingStyles';
import { language } from './UseCaseListingLanguage';
import { PaneLayout, Props as PaneProps } from '../../../../Components/Pane/PaneLayout';
import { UseCaseSection } from '../../../../Types/Portfolio/UseCaseSection';
import { generateUseCaseSections } from './helpers/generateUseCaseSections';
import { UseCaseType } from '../../../../Types/Portfolio/UseCaseType';

export type Props = {
	useCaseSections: { [key: string]: UseCaseType[] };
};

export const UseCaseListingLayout = (props: Props) => {
	const render = () => {
		const body = generateUseCaseSections(props.useCaseSections, []);
		const outgoingProps: PaneProps = {
			title: language.title,
			body,
		};
		const returnValue = (
			<UseCasesWrapper>
				<PaneLayout {...outgoingProps} />
			</UseCasesWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
