import {ConstStoreType} from './storeTypes';

export const ConstDefaultStore: ConstStoreType = {
	windowWidth: 1080,
	windowHeight: 1080,
	devMode: false,
	layoutType: "listLayout",
	loading: false,
	bookingMode: false,
};
