import { styled } from '@mui/material/styles';
import { Link } from '@mui/material';
import { cssVarColors } from '../../../../Constants';

export const HighlightsWrapper = styled('div')`
	//line-height: 1.8em;
`;

export const HighlightWrapper = styled('div')``;

export const HighlightLinkWrapper = styled(Link)`
	color: ${cssVarColors.primaryLight};
	text-decoration: none;
	:hover {
		color: ${cssVarColors.highlight};
	}
`;
