import * as React from 'react';
import { CardContentEnhanced, CardEnhanced, SitemapCardLinks, SitemapCardTitle } from '../SitemapStyles';
import { SitemapLink } from './SitemapLink';

export type Props = {
	card: any;
	key: string;
};

export const SitemapCard = (props: Props) => {
	const links = props.card.links.map((link: any) => {
		const returnValue = <SitemapLink link={link} />;
		return returnValue;
	});

	const render = (props: Props) => {
		const returnValue = (
			<CardEnhanced key={props.key}>
				<CardContentEnhanced>
					<SitemapCardTitle>{props.card.title}</SitemapCardTitle>
					<SitemapCardLinks>{links}</SitemapCardLinks>
				</CardContentEnhanced>
			</CardEnhanced>
		);
		return returnValue;
	};
	const outerReturnValue = render(props);
	return outerReturnValue;
};
