import * as React from 'react';
import { ComingSoonWrapper } from './ComingSoonStyles';
import { language } from './ComingSoonLanguage';

export type Props = {};

export const ComingSoonLayout = (props: Props) => {
	const render = () => {
		const returnValue = <ComingSoonWrapper>{language.text}</ComingSoonWrapper>;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
