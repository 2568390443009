import { EducationEntryType } from '../../../Types/EducationType';

export type SchemaType = {
	title: string;
	lines: Array<EducationEntryType>;
};

export const schema: SchemaType = {
	title: 'Education',
	lines: [
		{
			degree: 'BASc (Electrical Engineering)',
			school: 'University of Toronto',
			location: 'Toronto, Ontario',
			timespan: '2001-2005',
		},
		{
			degree: 'Juris Doctor',
			school: 'University of Western Ontario',
			location: 'London, Ontario',
			timespan: '2006-2009',
		},
		{
			degree: 'Masters of Law (LLM)',
			school: 'University of Western Ontario',
			location: 'London, Ontario',
			timespan: '2009-2011',
		},
		{
			degree: 'Techstars Alumnus',
			location: 'Austin, Texas',
			timespan: 'Winter 2016',
		},
		{
			degree: 'Culinary Arts Certificate',
			school: 'George Brown College',
			location: 'Toronto, Ontario',
			timespan: 'Winter 2025 (Pending)',
		},
	],
};
