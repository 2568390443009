import { styled } from '@mui/material/styles';

export const AboutMeWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	line-height: 1.8em;
`;

export const ParagraphWrapper = styled('div')`
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	:last-of-type {
		padding-bottom: 0;
	}
	:first-of-type {
		padding-top: 0;
	}
`;
