import { styled } from '@mui/material/styles';
import { Link } from '@mui/material';

export const NavButtonWrapper = styled(Link)`
	display: flex;
	flex-direction: row;
	flex: 1;
`;

export const NavButtonWrapperRight = styled(NavButtonWrapper)`
	justify-content: flex-end;
`;

export const IconWrapper = styled('div')`
	padding-right: 0.25rem;
	padding-left: 0.25rem;
`;

export const LabelWrapper = styled('div')`
	padding-left: 0.25rem;
	padding-right: 0.25rem;
`;
