import { UseCaseType } from '../../../Types/Portfolio/UseCaseType';
import { KidiUp } from '../Employment/KidiUp';

export const useCase: UseCaseType = {
	title: '#Activity Center Integration',
	employment: KidiUp,
	image: 'https://assets.dear-ux.com/faizhirani/images/Chowbotics Architecture.png',
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ["Children's Activity Centers post their upcoming class schedules on their websites to help parents choose the best classes for their children. Common formats included:", ['various types of weekly grid;', 'simple text lists;', 'embedded in body text about offerings; and', 'interactive widget.'], 'Additionally, this information was updated on an ad-hoc basis (often for holidays), as well as weekly, monthly, or per term.', "One of KidiUp's primary value propositions was to consolidate the Activity Centers and display information about their class schedules in a uniform way.", 'As an MVP, I designed a JSON interface to be populated with the scheduling data and an interpreter to import the data to our DynamoDB Tables. While outsourcing the process via Upwork kept costs low, it presented several challenges:', ["Time-consuming: Contractors had to visit each Activity Center's website, interpret the scheduling information, and transcribe it into the JSON template.", 'Prone to human error: The process involved numerical values that could be easily mistyped.', 'Difficult to coordinate: Managing the Upwork process added overhead.', 'Demanded high bandwidth: Managing contractors added overhead.', 'Unable to accommodate scheduling changes: Emergencies, weather, holidays, and illnesses were difficult to manage in real time.', 'Lacked consistency: Class names, capitalization, and age range representations varied.'], "At ~10 minutes per Activity Center, the contractor took ~25 hours to transcribe our pilot city's  150 Activity Centers. This did not include ad-hoc Activity Center website monitoring, or project management overhead. There was also a propagation lag between information posted on Activity Center websites and KidiUp.", 'It became clear to me that this process needed to be streamlined for scalability.'],
		},
		{
			sectionTitle: 'Solution',
			paras: ["With the recent release of OpenAI's ChatGPT and Assistants, I decided to explore these tools to solve the problem.  trained a large language model (LLM) to extract scheduling information directly from the HTML source code of Activity Center websites.", 'I then built a service around the OpenAI APIs that would capture a website twice daily and deliver the contents to the LLM. The output of the LLM was the populated JSON file required by the interpreter to import the data to the DynamoDB Table.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['This solution was an immediate success, transforming a cumbersome 25-hour manual task into a fully automated 10-minute process. The need for ad-hoc monitoring was eliminated, as the task could now be automated and triggered multiple times daily via Cloudwatch Events. Additionally, the LLM was not prone to data entry errors, and I trained it maintain consistency in form, so the information displayed by KidiUp was reliable and presented in a uniform way.', 'Moreover, we revised our $200k data-entry budget to just $2k in OpenAI usage fees—a 100-fold reduction. This adjustment led to an overall 20% reduction in our total funding request.'],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ["KidiUp's network infrastructure, which I designed for agility, was distributed, event-driven, and highly decoupled. As a result, external processes, such as schedule transcription, could be manually undertaken at first in order to flesh out the nuances, and the result POSTed to an API. ", 'Once the process was well understood, it was easy to create an independent service that would automate the process and publish the results as a notification to an SNS topic, which would ultimately be asynchronously consumed by the API.', 'This could be further refined, and amended for cost savings, or to integrate new features of OpenAI Assistants at our leisure.'],
		},
	],
};
