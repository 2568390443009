import { Highlight } from '../Layouts/Highlight';
import React from 'react';

export const generateHighlights = (highlights: { text: string; href?: string }[]) => {
	const returnValue = highlights.map((highlight: { text: string; href?: string }) => {
		const outgoingProps = {
			highlight,
		};
		const innerReturnValue = <Highlight {...outgoingProps} />;
		return innerReturnValue;
	});
	return returnValue;
};
