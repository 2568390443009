import { UseCaseType } from '../../../Types/Portfolio/UseCaseType';
import { Chowbotics } from '../Employment/Chowbotics';

export const useCase: UseCaseType = {
	title: '#Chowbotics Network Architecture',
	employment: Chowbotics,
	image: 'https://assets.dear-ux.com/faizhirani/images/Chowbotics Architecture.png',
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ['As Technical Director (Software), I was the first senior software resource hired at Chowbotics. At the time, Chowbotics’ backend software had been developed by a contractor and a junior software engineer.', 'This backend software supported ~10 Food Robots (Sallys) but was a cumbersome, unscalable monolithic architecture. There was an incident in my first week whereby an operator at the University of Berkley had given a custom salad a name with a special character, and as a result, all of our deployments, including those in Texas were taken offline.', 'As a Series A startup, Chowbotics had shown strong potential for product-market fit and was looking to scale. I was hired within this context and given full autonomy to restructure the backend software'],
		},
		{
			sectionTitle: 'Solution',
			paras: ['My first order of business was transitioning Chowbotics to a distributed network infrastructure capable of supporting indefinite deployments. The infrastructure featured asynchronous communication between REST APIs, backed by NoSQL databases and a SQL data warehouse. The design emphasized reliability, scalability, security, and fault tolerance. At its peak, the architecture easily supported 150 deployments, providing a solid foundation for the company’s growth.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['During my tenure at Chowbotics, in addition to the software initiatives that I spearheaded, I contributed to non-software initiatives, including:', ['Refining ingredient dispensing', 'Defining ingredient database', 'Researching and implementing culinary preferences', 'Increasing partner engagement', 'Conducting onsite user feedback sessions', 'Training and improving Sally Operator experience', 'Assessing customer engagement', 'Minimizing mechanical malfunctions', 'Enhancing user experience', 'Improving user retention and loyalty'], 'My curiosity and tenacity, paired with the agile infrastructure I built, positioned me as a go-to resource across departments. In critical moments, I was consistently called upon to provide key data by spinning off services from the events broadcast by the Sallys.', 'I accomplished all this while consistently meeting the ambitious goals set during our software sprints, demonstrating the effectiveness of the agile infrastructure in supporting both innovation and day-to-day operations.'],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ['As a Series A startup, Chowbotics was in constant pursuit of product-market fit, facing high ambiguity around end-user needs, market segmentation, and the product roadmap. Our team worked through this uncertainty by iterating rapidly — brainstorming new ways to converge on product-market fit. Agility became our most valuable resource, and the distributed architecture I built was designed specifically to facilitate that.'],
		},
	],
};
