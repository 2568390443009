import * as React from 'react';
import { AboutMeWrapper } from './AboutMeStyles';
import { schema as language } from '../../../Schema/CVs/default/AboutMe';
import { SectionTitleLayout, Props as SectionTitleProps } from '../../SectionTitle/SectionTitleLayout';
import { QuoteLayout, Props as QuoteProps } from '../../Quote/QuoteLayout';
import { BulletListLayout, Props as BulletListProps } from '../../BulletList/BulletListLayout';

export type Props = {};

export const AboutMeLayout = (props: Props) => {
	const render = () => {
		const sectionTitleProps: SectionTitleProps = {
			text: language.title,
		};

		const quoteProps: QuoteProps = {
			text: language.quote,
		};

		const bulletListProps: BulletListProps = {
			intro: language.intro,
			points: language.bullets,
			delimiter: ';',
			terminator: '.',
			lastConnector: 'and',
			style: { paddingLeft: '0rem' },
		};
		const returnValue = (
			<AboutMeWrapper>
				<QuoteLayout {...quoteProps} />
				<BulletListLayout {...bulletListProps} />
			</AboutMeWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
