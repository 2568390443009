// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* If you change something here you should change it in constants.tsx as well*/

body {
	background-color: #fff;
	color: #000;
}

:root {
	--background: #fcf4e7;
	--secondaryBackground: #f5f4ed;
	--onBackground: #012780dd;
	--surface: #ffffff;
	--onSurface: #355441;
	--onSurfaceHover: #aebab3;
	--onSurfaceRGB: 58, 71, 45;
	--onSurfaceBlue: #2d3a47;
	--onSurfaceYellow: #9d8f23;
	--onSurfaceSecondary: #2d3a47;
	--onSurfaceLight: #3ecc02;
	--primary: #62aef2;
	--onPrimary: #3a653a;
	--primaryAlternative: #62aef2;
	--onPrimaryAlternative: #74c378;
	--secondary: #fbe338;
	--onSecondary: #ffffff;
	--secondaryAlternative: #c8b52c;
	--onSecondaryAlternative: #ffffff;
	--highlight: #bf8414;
	--highlightLightHover: #f2e6d0;
	--highlightAlternative: #f57e04;
	--warning: #f6a50a;
	--error: #e02020;
	--disable: #b5b5b5;
	--disableRGB: 181, 181, 181;
	--dark: #262626;
	--placeholder: #001c4c;
	--odd: #01278011;
	--oddAlternate: #3a653a11;
}
`, "",{"version":3,"sources":["webpack://./src/Assets/css/override.css"],"names":[],"mappings":"AAAA,8EAA8E;;AAE9E;CACC,sBAAsB;CACtB,WAAW;AACZ;;AAEA;CACC,qBAAqB;CACrB,8BAA8B;CAC9B,yBAAyB;CACzB,kBAAkB;CAClB,oBAAoB;CACpB,yBAAyB;CACzB,0BAA0B;CAC1B,wBAAwB;CACxB,0BAA0B;CAC1B,6BAA6B;CAC7B,yBAAyB;CACzB,kBAAkB;CAClB,oBAAoB;CACpB,6BAA6B;CAC7B,+BAA+B;CAC/B,oBAAoB;CACpB,sBAAsB;CACtB,+BAA+B;CAC/B,iCAAiC;CACjC,oBAAoB;CACpB,8BAA8B;CAC9B,+BAA+B;CAC/B,kBAAkB;CAClB,gBAAgB;CAChB,kBAAkB;CAClB,2BAA2B;CAC3B,eAAe;CACf,sBAAsB;CACtB,gBAAgB;CAChB,yBAAyB;AAC1B","sourcesContent":["/* If you change something here you should change it in constants.tsx as well*/\n\nbody {\n\tbackground-color: #fff;\n\tcolor: #000;\n}\n\n:root {\n\t--background: #fcf4e7;\n\t--secondaryBackground: #f5f4ed;\n\t--onBackground: #012780dd;\n\t--surface: #ffffff;\n\t--onSurface: #355441;\n\t--onSurfaceHover: #aebab3;\n\t--onSurfaceRGB: 58, 71, 45;\n\t--onSurfaceBlue: #2d3a47;\n\t--onSurfaceYellow: #9d8f23;\n\t--onSurfaceSecondary: #2d3a47;\n\t--onSurfaceLight: #3ecc02;\n\t--primary: #62aef2;\n\t--onPrimary: #3a653a;\n\t--primaryAlternative: #62aef2;\n\t--onPrimaryAlternative: #74c378;\n\t--secondary: #fbe338;\n\t--onSecondary: #ffffff;\n\t--secondaryAlternative: #c8b52c;\n\t--onSecondaryAlternative: #ffffff;\n\t--highlight: #bf8414;\n\t--highlightLightHover: #f2e6d0;\n\t--highlightAlternative: #f57e04;\n\t--warning: #f6a50a;\n\t--error: #e02020;\n\t--disable: #b5b5b5;\n\t--disableRGB: 181, 181, 181;\n\t--dark: #262626;\n\t--placeholder: #001c4c;\n\t--odd: #01278011;\n\t--oddAlternate: #3a653a11;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
