import * as React from 'react';
import { TechnologiesWrapper, TechnologyTitleWrapper, TechnologyWrapper } from '../ProfessionalExperienceSectionStyles';
import { language } from '../ProfessionalExperienceSectionLanguage';

export type Props = {
	technologies: Array<string>;
};

export const TechnologiesLayout = (props: Props) => {
	const technologies = generateTechnologies(props.technologies);
	const render = () => {
		const returnValue =
			props.technologies.length === 0 ? null : (
				<TechnologiesWrapper>
					<TechnologyTitleWrapper>{language.technologiesUsedHeader}:</TechnologyTitleWrapper>
					{technologies}
				</TechnologiesWrapper>
			);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const generateTechnologies = (technologies: Array<string>) => {
	const returnValue = technologies.join(', ');
	return returnValue;
};
