import { useCase as bowlAutocompleteUseCase } from './useCases/bowlAutocomplete';
import { useCase as bowlCounterUseCase } from './useCases/bowlCounter';
import { useCase as repeatCustomers } from './useCases/repeatCustomers';
import { useCase as notificationStreams } from './useCases/notificationStreams';
import { useCase as canisterAnalysis } from './useCases/canisterEstimation';
import { useCase as fleetCommand } from './useCases/fleetCommand';
import { useCase as triageBot } from './useCases/triageBot';
import { useCase as partnerLaunchAutomation } from './useCases/partnerLaunchAutomation';
import { useCase as auroraStreetFestival } from './useCases/AuroraStreetFestival';
import { useCase as spider } from './useCases/spider';
import { useCase as COCs } from './useCases/COCs';
import { useCase as modelServiceStack } from './useCases/modelServiceStack';
import { useCase as partnerConfig } from './useCases/partnerConfig';
import { useCase as integratedSchedules } from './useCases/ActivityCenterIntegration';
import { useCase as ballotFileSystem } from './useCases/ballotFileSystem';
import { useCase as audioVoting } from './useCases/audioVoting';
import { useCase as timeMatters } from './useCases/timeMatters';
import { useCase as chowboticsInfrastructure } from './useCases/chowboticsInfrastructure';
import { useCase as languageAbstraction } from './useCases/languageAbstraction';
import { useCase as mobileAppDXRestructure } from './useCases/mobileAppDXRestructure';
import { useCase as dcf } from './useCases/dcf';
import { UseCaseWrapperType } from '../../Types/Portfolio/UseCaseWrapperType';

export const useCases: UseCaseWrapperType[] = [
	{
		id: 'modelServiceStack',
		section: 'KidiUp',
		content: modelServiceStack,
	},
	{
		id: 'scheduleIntegration',
		section: 'KidiUp',
		content: integratedSchedules,
		tags: ['agility'],
	},
	{
		id: 'auroraStreetFestivals',
		section: 'KidiUp',
		content: auroraStreetFestival,
	},
	{
		id: 'mobileAppDXRestructure',
		section: 'The Helper Bees',
		content: mobileAppDXRestructure,
		tags: ['developer experience'],
	},
	{
		id: 'launchAutomation',
		section: 'The Helper Bees',
		content: partnerLaunchAutomation,
		tags: ['developer experience'],
	},
	{
		id: 'partnerConfig',
		section: 'The Helper Bees',
		content: partnerConfig,
		tags: ['developer experience', 'agility'],
	},
	{
		id: 'helperBeesTriageBot',
		section: 'The Helper Bees',
		content: triageBot,
		tags: ['developer experience'],
	},
	{
		id: 'chowboticsInfrastructure',
		section: 'Chowbotics',
		content: chowboticsInfrastructure,
		tags: ['infrastructure'],
	},
	{
		id: 'chowboticsNotificationStreams',
		section: 'Chowbotics',
		content: notificationStreams,
		tags: ['agility'],
	},
	{
		id: 'chowboticsFleetCommand',
		section: 'Chowbotics',
		content: fleetCommand,
		tags: ['agility'],
	},
	// {
	// 	id: 'chowboticsRepeatCustomers',
	// 	section: 'Chowbotics',
	// 	content: repeatCustomers,
	// },
	{
		id: 'chowboticsBowlCounter',
		section: 'Chowbotics',
		content: bowlCounterUseCase,
		tags: ['agility'],
	},
	{
		id: 'chowboticsBowlAutocomplete',
		section: 'Chowbotics',
		content: bowlAutocompleteUseCase,
		tags: ['agility'],
	},
	{
		id: 'languageAbstraction',
		section: 'Chowbotics',
		content: languageAbstraction,
		tags: ['agility'],
	},
	{
		id: 'chowboticCanisterEstimation',
		section: 'Chowbotics',
		content: canisterAnalysis,
		tags: ['agility'],
	},
	{
		id: 'bambaCOCs',
		section: 'Bamba',
		content: COCs,
		tags: ['agility'],
	},
	{
		id: 'spider',
		section: 'Bamba',
		content: spider,
		tags: ['agility'],
	},
	{
		id: 'timeMatters',
		section: 'Community Legal Services',
		content: timeMatters,
		tags: ['agility'],
	},
	{
		id: 'dcf',
		section: 'Dominion Voting Systems',
		content: dcf,
		tags: ['agility', 'developer experience'],
	},
	{
		id: 'audioVoting',
		section: 'Dominion Voting Systems',
		content: audioVoting,
		tags: ['agility'],
	},
	{
		id: 'ballotFileSystem',
		section: 'Dominion Voting Systems',
		content: ballotFileSystem,
		tags: ['agility'],
	},
];

// Making sure the person who will interact with the solution has the best experience possible throughout their journey while still getting what's needed to meet internal departmental, company or client goals.

// eliminate, minimize, or if not possible, mitigate

// No structured way of being notified or addressing a malfunction or exception state from a food robot, so the task was being done ad-hoc.
