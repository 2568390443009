import { styled } from '@mui/material/styles';
import { mobileThreshold } from '../../../Constants/layoutValues';

export const HeaderWrapper = styled('div')`
	background-color: #333333;
	line-height: 2;
	display: flex;
	flex-direction: row;
	padding-top: 2rem;
	padding-bottom: 2rem;
	padding-right: 3rem;
	padding-left: 3rem;
	@media (max-width: ${mobileThreshold}px) {
		flex-direction: column;
		padding-left: 1rem;
		padding-right: 1rem;
	} ;
`;

export const ContentWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	padding-right: 2rem;
	flex-grow: 1;
`;

export const LogoWrapper = styled('div')`
	width: 200px;
	max-height: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	justify-self: flex-end;
	@media (max-width: ${mobileThreshold}px) {
		display: flex;
		width: 100%;
		padding-bottom: 1rem;
		align-items: center;
		justify-content: center;
		max-height: 100%;
	} ;
`;

export const Logo = styled('img')`
	max-height: 100%;
	max-width: 200px;
	width: auto;
	@media (max-width: ${mobileThreshold}px) {
		max-width: 70%;
		max-height: 30%;
	} ;
`;

export const UseCaseCompanyWrapper = styled('div')`
	display: flex;
	flex-direction: row;
`;

export const UseCasePositionWrapper = styled('div')`
	display: flex;
	flex-direction: row;
`;

export const UseCaseCompanyObjectiveWrapper = styled('div')``;

export const Label = styled('div')`
	flex: 1;
	@media (max-width: ${mobileThreshold}px) {
		padding-right: 0.5rem;
	} ;
`;

export const Content = styled('div')`
	flex: 5;
`;
