import * as React from 'react';
import { VersionWrapper } from './VersionStyles';
import { language } from './VersionLanguage';
const version = require('../../../package.json').version;
export type Props = {};

export const VersionLayout = (props: Props) => {
	const render = () => {
		const returnValue = (
			<VersionWrapper>
				{language.label} {version}
			</VersionWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
