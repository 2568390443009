import { LinkProps } from '@mui/material';
import { UseCaseListingWrapper } from '../PortfolioStyles';
import * as React from 'react';

export const generateUseCaseListing = (useCases: any) => {
	const returnValue = useCases.map((useCase: any) => {
		const outgoingProps: LinkProps = {
			href: '/portfolio/useCase?id=' + useCase.id,
		};
		const innerReturnValue = <UseCaseListingWrapper {...outgoingProps}>{useCase.title}</UseCaseListingWrapper>;
		return innerReturnValue;
	});
	return returnValue;
};
