import * as React from 'react';
import { AboutSectionWrapper, ContactContentWrapper } from './AboutSectionStyles';
import { language } from './AboutSectionLanguage';
import { AboutMeLayout } from './AboutMe/AboutMeLayout';
import { FancyHeaderLayout } from '../FancyHeader/FancyHeaderLayout';
import { MeritPanelLayout, Props as MeritPanelProps } from '../MeritPanel/MeritPanelLayout';
import { schema as skillsSchema } from '../../Schema/CVs/default/Skills';
import { cssVarColors } from '../../Constants';
import { schema as technologySchema } from '../../Schema/CVs/default/Technologies';
import { generateContactInfo, Props as ContactInfoProps } from './ContactInformation/helpers/generateContactInfoLines';
import { schema as contactSchema } from '../../Schema/CVs/default/ContactInfo';
import { schema as toolsSchema } from '../../Schema/CVs/default/Tools';
import { schema as ideologiesSchema } from '../../Schema/CVs/default/Ideologies';
import { schema as coreCompetenciesSchema } from '../../Schema/CVs/default/Core Competencies';
import { CvFilterType } from '../../Types/cvFilterType';

export type Props = {
	filter: CvFilterType;
};

export const AboutSectionLayout = (props: Props) => {
	const render = () => {
		const skillsProps: MeritPanelProps = {
			title: skillsSchema.title,
			filter: props.filter,
			backgroundColor: cssVarColors.surfaceAlternate,
			textColor: '#fff',
			lines: skillsSchema.lines,
		};

		const techProps: MeritPanelProps = {
			title: technologySchema.title,
			filter: props.filter,
			backgroundColor: cssVarColors.surfaceAlternate,
			textColor: '#fff',
			lines: technologySchema.lines,
		};

		const toolsProps: MeritPanelProps = {
			title: toolsSchema.title,
			filter: props.filter,
			backgroundColor: cssVarColors.surfaceAlternate,
			textColor: '#fff',
			lines: toolsSchema.lines,
		};

		const ideologies: MeritPanelProps = {
			title: ideologiesSchema.title,
			filter: props.filter,
			backgroundColor: cssVarColors.surfaceAlternate,
			textColor: '#fff',
			lines: ideologiesSchema.lines,
		};

		const coreCompetencies: MeritPanelProps = {
			title: coreCompetenciesSchema.title,
			filter: props.filter,
			backgroundColor: cssVarColors.surfaceAlternate,
			textColor: '#fff',
			lines: coreCompetenciesSchema.lines,
		};

		const contactInfoProps: ContactInfoProps = {
			contactInfoLines: contactSchema.lines,
		};
		const contactJSX = generateContactInfo(contactInfoProps);
		const contactProps: MeritPanelProps = {
			title: contactSchema.title,
			filter: props.filter,
			textColor: '#fff',
			backgroundColor: cssVarColors.surfaceAlternate,
			jsx: <ContactContentWrapper>{contactJSX}</ContactContentWrapper>,
		};

		const returnValue = (
			<AboutSectionWrapper>
				<FancyHeaderLayout />
				<AboutMeLayout />
				<MeritPanelLayout {...ideologies} />
				<MeritPanelLayout {...coreCompetencies} />
				<MeritPanelLayout {...skillsProps} />
				<MeritPanelLayout {...toolsProps} />
				<MeritPanelLayout {...techProps} />
				<MeritPanelLayout {...contactProps} />
			</AboutSectionWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
