import * as React from 'react';
import { CoreCompetencyWrapper } from '../CoreStyles';
import { language } from '../CoreLanguage';

export type Props = {
	coreCompetency: string;
};

export const CoreCompetency = (props: Props) => {
	const render = () => {
		const returnValue = <CoreCompetencyWrapper>{props.coreCompetency}</CoreCompetencyWrapper>;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
