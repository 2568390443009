import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const middleware = [thunk];

const configureStore = () => {
	const store = createStore(
		reducers,
		composeWithDevTools(applyMiddleware(...middleware)),
	);
	return store;
};

export default configureStore;
