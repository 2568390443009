import * as React from 'react';
import { ContactSectionWrapper } from './ContactSectionStyles';
import { language } from './ContactSectionLanguage';
import { PaneLayout } from '../../../../Components/Pane/PaneLayout';
import { ContactLineItem, Props as ContactLineItemProps } from './Layouts/ContactLineItem';

export type Props = {};

const contactLines = [
	{
		icon: ['fas', 'location-pin'],
		text: 'Toronto, Ontario, Canada',
	},
	{
		icon: ['fas', 'envelope'],
		text: 'faizhirani@gmail.com',
		link: 'mailTo:faizhirani@gmail.com',
	},
	{
		icon: ['fas', 'file'],
		text: 'Download CV',
		link: '/cv',
	},
];

export const ContactSectionLayout = (props: Props) => {
	const render = () => {
		const body = generateContactLines(contactLines);
		const PaneProps = {
			title: language.title,
			body: body,
		};
		const returnValue = (
			<ContactSectionWrapper>
				<PaneLayout {...PaneProps} />
			</ContactSectionWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const generateContactLines = (lines: any) => {
	const returnValue = lines.map((line: any) => {
		const contactLineItemProps: ContactLineItemProps = {
			icon: line.icon,
			text: line.text,
			link: line.link,
		};
		const innerReturnValue = <ContactLineItem {...contactLineItemProps} />;
		return innerReturnValue;
	});
	return returnValue;
};
