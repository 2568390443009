import { v4 as uuidv4 } from 'uuid';
import { SessionDefaultStore } from './store';
import { SessionActionTypes, AvailableActions } from './actionTypes';
import { SessionStoreType } from './storeTypes';
import { getClientIp } from '../../../../utils/getIP';
import {isStaging} from "../../../../utils/isStaging";

export const initialState = SessionDefaultStore;

export const SessionReducers = (
	state: SessionStoreType = initialState,
	action: SessionActionTypes
): SessionStoreType => {
	let currentDate: Date = new Date();
	switch (action.type) {
		case 'SESSION_ACTION': {
			let newState;
			if (state.session_id === '') {
				newState = {
					...state,
					portal: isStaging() ? "Sonar - Staging" : "Sonar",
					session_id: uuidv4(),
					session_start_date_time: currentDate.toISOString(),
					session_activity: state.session_activity.concat({
						timestamp: currentDate.toISOString(),
						currentPage: action.payload.currentPage,
						action: AvailableActions.BEGUN,
						details: 'MILESTONE - Session Started',
					}),
				};
			} else {
				newState = {
					...state,
					session_activity: state.session_activity.concat({
						timestamp: currentDate.toISOString(),
						currentPage: action.payload.currentPage,
						action: action.payload.action,
						details: action.payload.details,
					}),
				};
			}
			return newState;
		}
		case 'END_SESSION': {
			return {
				...initialState,
			};
		}
		case 'MARK_AS_BUSINESS_SESSION': {
			let newState = {
				...state,
				session_business_session: true,
			};
			return newState;
		}
		default:
			return state;
	}
};
