import * as React from 'react';
import { Content, ContentWrapper, HeaderWrapper, Label, Logo, LogoWrapper, UseCaseCompanyObjectiveWrapper, UseCaseCompanyWrapper, UseCasePositionWrapper } from './HeaderStyles';
import { language } from '../UseCaseLanguage';
import { UseCaseType } from '../../../Types/Portfolio/UseCaseType';
import { useMobile } from '../../../utils/useMobile';

export type Props = {
	useCase: UseCaseType;
};

export const HeaderLayout = (props: Props) => {
	const isMobile = useMobile();
	const render = () => {
		const logoProps = {
			src: props.useCase.employment.logo,
		};
		const desktopJSX = (
			<HeaderWrapper>
				<ContentWrapper>
					<UseCaseCompanyWrapper>
						<Label>Company:</Label>
						<Content>{props.useCase.employment.company}</Content>
					</UseCaseCompanyWrapper>
					<UseCasePositionWrapper>
						<Label>Position:</Label>
						<Content>{props.useCase.employment.position}</Content>
					</UseCasePositionWrapper>
					<UseCaseCompanyObjectiveWrapper>{props.useCase.employment.objective}</UseCaseCompanyObjectiveWrapper>
				</ContentWrapper>
				<LogoWrapper>
					<Logo {...logoProps} />
				</LogoWrapper>
			</HeaderWrapper>
		);
		const mobileJSX = (
			<HeaderWrapper>
				<LogoWrapper>
					<Logo {...logoProps} />
				</LogoWrapper>
				<ContentWrapper>
					<UseCaseCompanyWrapper>
						<Content>{props.useCase.employment.company}</Content>
					</UseCaseCompanyWrapper>
					<UseCasePositionWrapper>
						<Content>{props.useCase.employment.position}</Content>
					</UseCasePositionWrapper>
					<UseCaseCompanyObjectiveWrapper>{props.useCase.employment.objective}</UseCaseCompanyObjectiveWrapper>
				</ContentWrapper>
			</HeaderWrapper>
		);
		const returnValue = isMobile ? mobileJSX : desktopJSX;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
