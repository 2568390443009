import {SNACKBAR_CLEAR, SNACKBAR_SUCCESS, SnackbarActionTypes} from "./actionTypes";
import {SnackbarDefaultStore} from "./store";

export const SnackbarReducers = (state = SnackbarDefaultStore, action: SnackbarActionTypes) => {
	switch (action.type) {
		case SNACKBAR_SUCCESS:
			return {
				...state,
				open: true,
				message: action.payload.message
			};
		case SNACKBAR_CLEAR:
			return {
				...state,
				open: false,
			};
		default:
			return state;
	}
};
