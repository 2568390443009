import * as React from 'react';
import { ProfessionalExperienceEntriesType } from '../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntriesType = [
	{
		showMeOn: ['all'],
		length: '~2 Year',
		timespan: 'March 2022 - Present',
		title: 'Founder & Chief Technology Officer',
		technologies: ['ReactJS', 'NodeJS', 'NoSQL (DynamoDB)', 'SQL (OLAP)', 'OpenAI', 'Bash Scripting'],
		tools: ['AWS', 'Google Cloud', 'Hootsuite', 'Blender', 'Adobe Photoshop', 'Stable Diffusion', 'Final Cut Pro'],
		company: 'KidiUp',
		description: "A platform to find, book, pay and keep track of children's activities all in one place",
		location: 'Toronto, Ontario',
		details: [
			{
				showMeOn: ['condensed'],
				entry: 'Designed and deployed distributed event-based network architecture',
			},
			{
				showMeOn: ['all'],
				entry: 'Designed and iteratively developed a public-facing webapp with the flexibility for quick adjustments based on feedback',
			},
			{
				entry: 'Developed scalable autonomous web crawler and integrated Large Language Model assistant to extract and manage Activity Center data in real-time',
				showMeOn: ['all'],
			},
			{
				showMeOn: ['all'],
				entry: 'Defined and tracked Key Performance Indicators (KPIs) to inform  agile responses to data and strategic rapid pivots',
			},
			{
				showMeOn: ['all'],
				entry: 'Led strategic sessions to develop a flexible Product Roadmap, enabling rapid iterations in response to market shifts',
			},
			{
				showMeOn: ['all'],
				entry: 'Designed and maintained modularized homepage to facilitate agile A/B testing of marketing content',
			},
			{
				showMeOn: ['all'],
				entry: 'Designed and created collateral to be used at marketing events (such as signs, aisle banners, and leaflets)',
			},
			{
				showMeOn: ['all'],
				entry: 'Managed digital and social media marketing channels, making data-driven adjustments to improve engagement',
			},
			{
				showMeOn: ['all'],
				entry: 'Directed, produced, and co-starred in amusing 3-minute sketch for a fundraising application',
			},
		],
	},
	{
		showMeOn: ['all'],
		length: '~4 years',
		timespan: 'Nov 2022 - Present,;June 2020 - March 2022',
		title: 'Staff Software Engineer / Independent Contractor',
		technologies: ['Flask', 'Django', 'ReactJS', 'React Native', 'NodeJS', 'SQL (OLTP)', 'Bash Scripting'],
		tools: ['Google Cloud', 'Microsoft Azure', 'Ansible', 'Docker', 'CircleCI', 'Expo', 'Jest', 'Cypress'],
		company: 'The Helper Bees',
		description: 'A company that provides seniors with tools to help them age-in-place',
		location: 'Austin, Texas (Remote)',
		results: 'Jan 2022 - 12.8M Series B raise',
		details: [
			{
				showMeOn: ['all'],
				entry: 'Spearheaded UX redesign of internal dashboards for Ops teams, driving iterative improvements based on user feedback',
			},
			{
				showMeOn: ['all'],
				entry: 'Led overhaul of monolithic architecture, simplifying DX to enable agile development cycles.',
			},
			{
				showMeOn: ['all'],
				entry: 'Automated partner onboarding process, reducing onboarding time from 2 weeks to 30 minutes',
			},
			{
				showMeOn: ['all'],
				entry: 'Mentored teammates through peer programming and code reviews, fostering a collaborative and adaptive culture',
			},
			{
				showMeOn: ['all'],
				entry: 'Implemented dashboard for stakeholders to monitor KPIs, enabling data-driven decisions and iterative improvements',
			},
		],
	},
	{
		showMeOn: ['all'],
		length: '~2 years',
		timespan: 'June 2018 - April 2020',
		title: 'Technical Director (Software)',
		technologies: ['Angular', 'ReactJS', 'React Native', 'NodeJS', 'Express', 'SQL (OLTP)', 'SQL (OLAP)', 'NoSQL (DynamoDB / MongoDB)'],
		tools: ['AWS', 'Microsoft Azure', 'Expo', 'Cypress', 'PostHog', 'Papertrail', 'Jira', 'Excalidraw'],
		company: 'Chowbotics',
		description: 'A company that manufactured and sold food robots',
		location: 'Hayward, California',
		employeeNumber: {
			type: 'approximate',
			number: 25,
		},
		results: 'Oct 2020 - Acquired by Doordash',
		details: [
			{
				showMeOn: ['all'],
				entry: 'Designed and maintained distributed network infrastructure and data warehouse, ensuring scalability and reliability',
			},
			{
				showMeOn: ['all'],
				entry: 'Created partner-facing portal to monitor sales, status, and maintenance, supporting iterative improvements',
			},
			{
				showMeOn: ['all'],
				entry: 'Contributed to the development of the mobile app used at the Robot terminal, ensuring seamless system integration',
			},
			{
				showMeOn: ['all'],
				entry: 'Developed disaster recovery routines to mitigate mechanical failures and maintaining service continuity',
			},
			{
				showMeOn: ['all'],
				entry: 'Collaborated with Ops Team increasing monitoring and maintaining capacity of CSM from ~3 to ~50 Robots',
			},
			{
				showMeOn: ['all'],
				entry: 'Collaborated with Culinary Team to tailor offerings based on local iterative feedback',
			},
			{
				showMeOn: ['all'],
				entry: 'Partnered with Marketing Team to promote, and evaluate marketing initiatives, using data to drive campaign success',
			},
			{
				showMeOn: ['all'],
				entry: 'Collaborated with Mech Eng Team in resolving malfunctions to improve operational efficiency and reduce downtime',
			},

			{
				showMeOn: ['all'],
				entry: 'Developed algorithms and infrastructure to synthesize behavioral patterns and drive product and UX improvements',
			},
			{
				showMeOn: ['all'],
				entry: 'Observed real-world user interactions with Robots to identify and address iteratively UX deficiencies',
			},
			{
				showMeOn: ['all'],
				entry: 'Established common terminology to improve internal communication and reduce misunderstandings',
			},
		],
	},
	{
		showMeOn: ['all'],
		length: '~7 years',
		timespan: 'Sept 2011 - Jan 2018',
		title: 'Founder & Chief Technology Officer',
		technologies: ['LAMP', 'Java', 'jQuery', 'NodeJS', 'Express', 'Blender'],
		tools: ['AWS', 'Twilio', "Africa's Talking"],
		company: 'Bamba',
		description: 'A company that provided tools for data collection in emerging markets',
		location: 'Toronto, Ontario',
		results: 'Jan 2018 - Acquired by Maximeyes Group (https://maximeyesgroup.me/)',
		details: [
			{
				showMeOn: ['all'],
				entry: 'Invented referral-incentive algorithm yielding 50k+ participants from a seed of 20',
			},
			{
				showMeOn: ['all'],
				entry: 'Designed and implemented traditional, then distributed, network architecture to improve scalability and flexibility',
			},
			{
				showMeOn: ['all'],
				entry: 'Created portal to design, launch, and monitor data collection, with reporting capabilities',
			},
			{
				showMeOn: ['all'],
				entry: 'Structured relational database to manage data streams from 500k+ users',
			},
			{
				showMeOn: ['all'],
				entry: 'Developed algorithms to cleanse, homogenize, and ensure data integrity',
			},
			{
				showMeOn: ['all'],
				entry: 'Integrated with APIs to support diverse data streams (SMS, email, WhatsApp, etc.)',
			},
			{
				showMeOn: ['all'],
				entry: 'Devised defenses against suspicious behaviors (e.g., straight-lining, randomizing)',
			},
			{
				showMeOn: ['all'],
				entry: 'Attended TechStars, Austin (Winter 2016), to facilitate company growth',
			},
		],
	},
	{
		showMeOn: ['all'],
		length: '~2 years',
		timespan: 'Sept 2012 - Dec 2014',
		title: 'Contract Engineer',
		technologies: ['Apex', 'jQuery'],
		tools: ['Salesforce'],
		company: 'BigKite Consulting',
		description: 'A SalesForce consulting company',
		location: 'Toronto, Ontario',
		results: 'Sept 2015 - Acquired by Traction (https://tractionondemand.com)',
		details: [
			{
				showMeOn: ['all'],
				entry: 'Assisted customers in defining and mapping essential business processes to Salesforce',
			},
			{
				showMeOn: ['all'],
				entry: 'Prepared high-level and technical design documents',
			},
			{
				showMeOn: ['all'],
				entry: 'Managed projects, ensuring on-time delivery and alignment with client goals',
			},
		],
	},
	{
		showMeOn: ['all'],
		length: '~3 years',
		timespan: 'Sept 2008 - May 2011',
		title: 'Case Worker / Student Supervisor / Articling Student',
		company: 'Community Legal Services',
		description: 'A legal clinic overseen by lawyers, but staffed by Law Students',
		location: 'London, Ontario',
		technologies: [],
		tools: [],
		details: [
			{
				showMeOn: ['all'],
				entry: (
					<>
						Reverse-engineered undocumented software (<i>Time Matters</i>) to reduce an 8-hour weekly task to 10 minutes.
					</>
				),
			},
			{
				showMeOn: ['all'],
				entry: 'Scripted, directed, and produced a series of instructional videos for a litigation course',
			},
		],
	},
	{
		showMeOn: ['all'],
		length: '~1 year',
		timespan: 'Aug 2005 - Aug 2006',
		title: 'Software Engineer',
		technologies: ['C++', '.Net', 'SQL (SQLite)'],
		tools: [],
		company: 'Dominion Voting Systems',
		description: 'A company that makes voting machines to be used in elections',
		location: 'Toronto, Ontario',
		results: '75M annual revenue',
		employeeNumber: {
			type: 'approximate',
			number: 14,
		},
		details: [
			{
				showMeOn: ['all'],
				entry: 'Developed embedded dynamic configuration software for the CF200 Polling Machine, enabling flexible and scalable deployments',
			},
			{
				showMeOn: ['all'],
				entry: 'Invented File System and tools to meet election day I/O bandwidth requirements',
			},
			{
				showMeOn: ['all'],
				entry: 'Designed and implemented buzzer-based UX enabling visually impaired voters to cast ballots',
			},
		],
	},
	{
		showMeOn: ['all'],
		length: '~8 years',
		timespan: 'Sept 2001 - June 2009',
		title: 'Webmaster',
		company: 'Gridiron Junkies (GJ)',
		description: 'An American Football opinion website',
		location: 'Toronto, Ontario',
		technologies: ['Linux', 'Apache', 'SQL DB (MySQL)', 'PHP', 'jQuery', 'AJAX'],
		tools: [],
		details: [
			{
				showMeOn: ['all'],
				entry: 'As punishment for competence, was unanimously voted to be Webmaster against my will',
			},
			{
				showMeOn: ['all'],
				entry: 'Developed a content management system enabling article publishing and homepage customization',
			},
			{
				showMeOn: ['all'],
				entry: 'In 2016, Al Ismaili (Contributor to GJ, CEO of Bamba) referred to it as "WordPress before WordPress"',
			},
		],
	},
	{
		showMeOn: ['all'],
		length: `${new Date().getFullYear() - 2000} years`,
		technologies: [],
		tools: [],
		timespan: '2000 - Present',
		title: 'Unspeakable',
		company: 'Department of Mysteries (Ministry of Magic)',
		description: '???',
		location: 'London, England',
		details: [],
	},
];
