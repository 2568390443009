import React from 'react';
import { generateUseCaseListing } from '../../../helpers/generateUseCaseListing';
import { UseCaseSectionContent, UseCaseSectionTitle, UseCaseSectionWrapper } from '../../../PortfolioStyles';
import { UseCaseType } from '../../../../../Types/Portfolio/UseCaseType';

export const generateUseCaseSections = (useCaseSections: { [key: string]: UseCaseType[] }, filters: string[] = []) => {
	const keys = Object.keys(useCaseSections);
	const returnValue: React.ReactNode[] = [];
	keys.forEach((key) => {
		const useCases = useCaseSections[key];
		const content = generateUseCaseListing(useCases);
		const innerReturnValue = (
			<UseCaseSectionWrapper>
				<UseCaseSectionTitle>{key}</UseCaseSectionTitle>
				<UseCaseSectionContent>{content}</UseCaseSectionContent>
			</UseCaseSectionWrapper>
		);
		returnValue.push(innerReturnValue);
	});
	return returnValue;
};
