import * as React from 'react';
import { CoreWrapper } from './CoreStyles';
import { language } from './CoreLanguage';
import { PaneLayout, Props as PaneProps } from '../../../../Components/Pane/PaneLayout';
import { generateCoreCompetencies } from './helpers/generateCoreCompetencies';
import { coreCompetence } from '../../../../Schema/Portfolio/coreCompetence';

export type Props = {};

export const CoreLayout = (props: Props) => {
	const render = () => {
		const paneProps: PaneProps = {
			title: language.title,
			body: generateCoreCompetencies(coreCompetence),
		};
		const returnValue = (
			<CoreWrapper>
				<PaneLayout {...paneProps} />
			</CoreWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
