import * as React from 'react';
import { PaneBody, PaneTitle, PaneWrapper } from './PaneStyles';
import { language } from './PaneLanguage';
import { generateParas } from '../../Pages/Portfolio/Layouts/AboutMe/helpers/generateParagraphs';

export type Props = {
	title: string | React.ReactNode;
	body: string | React.ReactNode | string[];
};

export const PaneLayout = (props: Props) => {
	const render = () => {
		const body = Array.isArray(props.body) ? generateParas(props.body) : props.body;
		const returnValue = (
			<PaneWrapper>
				<PaneTitle>{props.title}</PaneTitle>
				<PaneBody>{body}</PaneBody>
			</PaneWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
