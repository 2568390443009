import * as React from 'react';
import { GeneralViewPortWrapper } from './GeneralViewPortStyles';
import { language } from './GeneralViewPortLanguage';
import { PersistantHeaderLayout } from '../PersistantHeader/PersistantHeaderLayout';

export type Props = {
	internalJSX: React.ReactNode;
};

export const GeneralViewPortLayout = (props: Props) => {
	const render = () => {
		const returnValue = (
			<GeneralViewPortWrapper>
				<PersistantHeaderLayout />
				{props.internalJSX}
			</GeneralViewPortWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
