import { Paragraph, Props as ParagraphProps } from '../Layouts/Paragraph';
import React from 'react';

export const generateParas = (paras: string[]) => {
	const returnValue = paras.map((para: any, index: number) => {
		const outgoingProps: ParagraphProps = {
			para,
		};
		const innerReturnValue = <Paragraph {...outgoingProps} key={'paragraph' + index} />;
		return innerReturnValue;
	});
	return returnValue;
};
