import { UseCaseType } from '../../../Types/Portfolio/UseCaseType';
import { DominionVotingSystems } from '../Employment/Dominion Voting Systems';

export const useCase: UseCaseType = {
	title: '#Audio Voting UX',
	// image: 'https://assets.dear-ux.com/faizhirani/images/Chowbotics Architecture.png',
	employment: DominionVotingSystems,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ["As part of the accessibility requirements, Dominion Voting Systems' CF200 Poll Tabulator needed to allow those with visual impairments to cast a ballot. As a small startup, most software resources were focused on the majority of electoral ballots, and the task of ensuring a smooth user experience for the visually impaired fell to me. I was given a budget to schedule a single 3-hour consultation with an expert from the Canadian Institute for the Blind (CIB) to guide me, and how or when to use this consultation was left entirely to my discretion."],
		},
		{
			sectionTitle: 'Solution',
			paras: ['After researching how visually impaired individuals interact with similar systems, and reviewing how the general public interacts with automated touch-tone services, I designed a configuration file that controlled every aspect of the audio voting process. I also implemented an interpreter to manage the voting flow based on the configuration file.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['This approach worked extremely well. I scheduled the consultation after developing a working prototype. During the 3-hour session, I iterated quickly by adjusting parameters in the configuration file. The CIB resource tested multiple ballot-casting experiences, provided feedback, and I incorporated it in real-time. I successfully completed the task within the allotted budget, and there were no complaints on election day.'],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ["I leveraged the internalized knowledge of Developer Experience (DX) that I had gained from <href='/portfolio/useCase?id=dcf'>DCF File</href>. Positive DX enables fast iteration toward an optimal solution, typically allowing developers to respond to user and market feedback over days or weeks. In this case, the agility afforded by DX was applied in a live working session, allowing immediate feedback integration and rapid re-testing."],
		},
	],
};
