import styled from 'styled-components';

export const BackgroundTitleWrapper = styled.div`
	font-size: 1.3em;
	background-color: #98a8be;
	-webkit-print-color-adjust: exact;
	color: #fff;
	text-transform: uppercase;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	@media print {
		font-size: 1.15em;
	}
`;
