import styled from 'styled-components';
import { cssVarColors } from '../../Constants';
import { mobileThreshold } from '../../Constants/layoutValues';

export const MeritPanelWrapper = styled.div`
	flex: 1;
	padding-bottom: 1.5rem;
	height: fit-content;
	max-height: fit-content;
	@media print {
		padding-bottom: 0.5rem;
	}
	@media (max-width: ${mobileThreshold}px) {
		padding-left: 0;
		padding-right: 0;
	} ;
`;

export const MeritsContentWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 0.9em;
	@media print {
		padding-top: 0.25rem;
		font-size: 0.9em !important;
	}
	@media (max-width: ${mobileThreshold}px) {
		margin-left: 0;
		margin-right: 0;
	} ;
`;

export const MeritLineItemWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	padding-top: 0.1em;
	padding-bottom: 0.1em;
	@media print {
		padding-bottom: 0.1em;
		padding-top: 0.1em;
		:last-of-type {
			padding-bottom: 0;
		}
	}
`;

export const MeritLineText = styled('div')``;

export const MeritLineRanking = styled('div')`
	padding-right: 0.5rem;
`;

export const MeritRankingsWrapper = styled('div')`
	display: flex;
	flex-direction: row;
`;

export const MeritDotWrapper = styled('div')`
	margin-left: 0.25em;
	margin-right: 0.25em;
	font-size: 0.5em;
	color: ${cssVarColors.highlight};
	@media print {
		font-size: 4px !important;
	}
`;
