import styled from 'styled-components';
import { mobileThreshold } from '../../Constants/layoutValues';

export const QuoteWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	font-style: italic;
	padding-bottom: 0.75em;
	//padding-left: 1rem;
	@media (max-width: ${mobileThreshold}px) {
		padding-left: 0;
	} ;
`;
