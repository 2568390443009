import styled from 'styled-components';
import { Card, CardContent } from '@mui/material';
import {cssVarColors} from "../../Constants";

export const SitemapLayoutWrapper = styled.div`
	flex-direction: column;
	display: flex;
	flex: 1;
  background-color: ${cssVarColors.dark};
`;

export const Title = styled.div`
	font-size: 2em;
	padding-top: 0.5em;
	padding-bottom: 0.25em;
	text-align: center;
	color: ${cssVarColors.highlight};
`;

export const Content = styled.div`
	padding-top: 0.25em;
	flex-direction: row;
	display: flex;
`;

export const CardEnhanced = styled(Card)`
	flex: 1;
	background-color: #fff;
	color: #000;
	animation-duration: 6s;

	margin: 0.25em;
	display: flex;
	flex-direction: row;
	border-radius: 15px;
	position: relative;
	align-items: flex-start;
`;

export const CardContentEnhanced = styled(CardContent)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
  flex: 1;
  padding-bottom: 1em !important;
  padding-top: 1em !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

export const SitemapCardTitle = styled.div`
	padding-top: 0.5em;
	padding-bottom: 0.5em;
  padding-left: .5em;
  padding-right: .5em;
	font-size: 1.6em;
  color: ${cssVarColors.highlight}
`;

export const SitemapCardLinks = styled.div`
	padding-top: 0.5em;
	padding-bottom: 0.5em;
  padding-left: .5em;
  padding-right: .5em;
  flex: 1;
`;


export const SitemapLinkWrapper = styled.div`
	display: flex;
	flex-direction: row;
  padding-top: .5em;
  padding-bottom: .5em;
  padding-left: .5em;
  padding-right: .5em;
  width: 100%;
  color: ${cssVarColors.onSurface};
  &:hover
  {
    color: #fff;
    background-color: ${cssVarColors.highlight};
  }
`;

export const SitemapLinkTitle = styled.a`
	text-decoration: none;
	color: inherit;
	&:visited {
      color: inherit;
	}
	&:hover {
		color: inherit;
	}
`;
