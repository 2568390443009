import { UseCaseType } from '../../../Types/Portfolio/UseCaseType';
import { KidiUp } from '../Employment/KidiUp';

export const useCase: UseCaseType = {
	title: '#Model Service Stack',
	employment: KidiUp,
	image: 'https://assets.dear-ux.com/faizhirani/images/KidiUp Model Stack.png',
	body: [
		{
			sectionTitle: 'Trigger',
			columns: 2,
			paras: ['Implementing a distributed network architecture to support Bamba and Chowbotics was an extremely rewarding endevour. As promised by my research, the infrastructure resulted in much more robust, scalable, and versatile, foundations on which to build our platforms. However, managing microservice resources introduced a host of configuration challenges, such as varying:', ['DynamoDB read/write capacity', 'Lambda Timeout', 'Lamba Max Memory Allocation', 'SQS redrive policies', 'SQS Queue Names', 'SNS Topic Names', 'SNS FIFO booleans', 'ACL Policies'], 'These slight variations led to inconsistencies across our network infrastructure, creating operational headaches. Despite the challenges these variations caused, I grew fond of the AWS cloud, and subscribed to its newsletter.', 'In July 2019, when AWS announced the Cloud Development Kit (CDK) for provision and configuring resources, I immediately saw an opportunity to alleviate the headaches. Even though I had no live microservices project at the time, I jumped into action.'],
		},
		{
			sectionTitle: 'Solution',
			columns: 1,
			paras: ['I developed a model microservices stack fully optimized for continuous delivery and deployment. The stack integrated:', ['Code Commit, Code Pipeline, and Code Build: For streamlined CI/CD;', 'SNS, SQS, and API Gateway: For event-driven architecture and communication;', 'DynamoDB, and Lambda: For scalable data storage and compute;', 'Cloudwatch Logs, and Cloudwatch Events: For monitoring and automated responses;', 'Route 53, CloudFront, and S3: For low latency in delivering content; and', 'Certificate Manager, and Secret Manager: For secure service delivery.']],
		},
		{
			sectionTitle: 'Impact',
			paras: ["As a pre-funding startup with a highly ambiguous product roadmap, KidiUp's infrastructure used this microservice stack. Its agility was formidable. With this infrastructure, we quickly designed experiments, ran A/B tests, and scrutinized user behavior data to resolve ambiguity and gain insights into the market and our place in it.", 'With a deployment cycle time measured in minutes, the engineering team could rapidly implement ideas from brainstorming sessions without encountering resistance from the architecture. The natural scalability of the microservices architecture ensured zero service outages, no scaling issues, no security lapses, and seamless communication between services.', 'As a result, the software team focused entirely on supporting KidiUp’s fundraising goals, an achievement I’ve found to be rare in startup environments, even within the Techstars ecosystem.'],
		},
	],
};
