import { UseCaseType } from '../../../Types/Portfolio/UseCaseType';
import { Bamba } from '../Employment/Bamba';

export const useCase: UseCaseType = {
	title: '#Referral Incentive Algorithm',
	image: 'https://assets.dear-ux.com/faizhirani/images/Spider.png',
	employment: Bamba,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ['To collect data in emerging markets, Bamba needed to build a proprietary user base in each market, segmented by location, gender, and socio-economic factors, to engage on behalf of clients.', 'While we had built a small user base in a Kenyan city, expanding required a more robust user base in each locale.', 'This challenge was presented to me by the Bamba CEO during our journey to Techstars Austin.', 'I immediately saw a solution that leveraged our existing software architecture.'],
		},
		{
			sectionTitle: 'Solution',
			paras: ['I hypothesized that individuals in these markets would respond well to a referral incentive structure. I then implemented an algorithm that:', ['Engaged a user by offering 10 Ksh in airtime (~10 US cents) for completing a short survey.', 'Administered the survey to gather core segmentation data.', 'Paid the user the incentive via instant airtime transfer, establishing value.', 'Offered an additional 10 Ksh for every 3 friends they referred who completed the survey.', 'Reached out to referred phone numbers, citing the referrer by name.'], 'Once the algorithm was set, the Operations Team provided a small sample (typically 20 phone numbers) to initiate engagement.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['The success of this approach was extraordinary.', 'In the first test, I seeded the algorithm with a list of 20 people in Nairobi, Kenya. After 8 hours, we had 18,000 referrals and roughly 4,000 completed segmentation surveys.', 'The only reason the numbers stopped there was that we exhausted the credits I had deposited for the experiment, preventing further user engagement.', 'Through further iteration, the algorithm was refined to target specific population segments, moving beyond the initial shotgun approach.'],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ['Two key factors enabled the rapid hypothesis and implementation of this solution.', 'Bamba’s agile, distributed, event-driven, and highly decoupled network infrastructure, which I designed, enabled the rapid deployment of a hypothesis-testing prototype. This architecture made it simple to create a service dedicated to collecting and engaging referrals as soon as a user completed a survey', 'Secondly, being close to the raw data, I had observed patterns that led me to intuit the success of a referral-based incentive algorithm.', 'Specifically, I noticed that survey completions were clustered — users were more likely to complete surveys if their geographic neighbors had done so, suggesting word-of-mouth spread regarding the incentives. Additionally, there was chatter on our SMS lines after survey completions, indicating that once incentives were paid, users were actively seeking further opportunities to engage.'],
		},
	],
};
