import { TechnologyType } from '../../../Types/TechnologyType';
import { ToolsType } from '../../../Types/ToolsType';

export type SchemaType = {
	title: string;
	lines: Array<ToolsType>;
};

export const schema: SchemaType = {
	title: 'Ideologies',
	lines: [
		{
			entry: 'Agility',
		},
		{
			entry: 'Market-Driven Development',
		},
		{
			entry: 'User Experience (UX)',
		},
		{
			entry: 'Developer Experience (DX)',
		},
		{
			entry: 'Empathy-Driven Design',
		},
		{
			entry: 'Automation as a Force Multiplier',
		},
		{
			entry: 'Fail Fast - Learn Faster',
		},
		{
			entry: 'Bias For Action',
		},
	],
};
