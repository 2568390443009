import * as React from 'react';
import { SitemapLinkWrapper, SitemapLinkTitle } from '../SitemapStyles';

export type Props = {
	link: {
		title: string;
		link: string;
	};
};

export const SitemapLink = (props: Props) => {
	const render = (props: Props) => {
		const returnValue = (
			<SitemapLinkWrapper>
				<SitemapLinkTitle href={props.link.link}>{props.link.title}</SitemapLinkTitle>
			</SitemapLinkWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render(props);
	return outerReturnValue;
};
