import {
	ActionNames,
	ConstActionTypes, SET_DEV_MODE_TYPE, SET_LAYOUT_TYPE_TYPE, SET_WINDOW_WIDTH_TYPE, SET_WINDOW_HEIGHT_TYPE, SET_LOADING_TYPE, SET_BOOKING_MODE_TYPE
} from './actionTypes';

export const setWindowWidth = (width: number): ConstActionTypes =>
{
	const returnValue: SET_WINDOW_WIDTH_TYPE = {
		type: 'SET_WINDOW_WIDTH',
		payload: {
			width
		},
	};
	return returnValue;
}

export const setWindowHeight = (height: number): ConstActionTypes =>
{
	const returnValue: SET_WINDOW_HEIGHT_TYPE = {
		type: 'SET_WINDOW_HEIGHT',
		payload: {
			height
		},
	};
	return returnValue;
}

export const setDevMode = (devModeOn: boolean): ConstActionTypes =>
{
	const returnValue: SET_DEV_MODE_TYPE = {
		type: "SET_DEV_MODE",
		payload: {
			devModeOn
		},
	};
	return returnValue;
}

export const setLayoutType = (layoutType: string): ConstActionTypes =>
{
	const returnValue: SET_LAYOUT_TYPE_TYPE = {
		type: "SET_LAYOUT_TYPE",
		payload: {
			layoutType
		},
	};
	return returnValue;
}

export const setLoading = (loading: boolean): ConstActionTypes =>
{
	const returnValue: SET_LOADING_TYPE = {
		type: "SET_LOADING",
		payload: {
			loading
		},
	};
	return returnValue;
}

export const setBookingMode = (bookingMode: boolean): ConstActionTypes =>
{
	const returnValue: SET_BOOKING_MODE_TYPE = {
		type: "SET_BOOKING_MODE",
		payload: {
			bookingMode
		},
	};
	return returnValue;
}

export const ConstantsActions = {
	setWindowWidth,
	setWindowHeight,
	setDevMode,
	setLayoutType,
	setLoading,
	setBookingMode
};
