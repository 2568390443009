import * as React from 'react';
import { BodySection, CvWrapper } from './cvStyles';
import { AboutSectionLayout, Props as AboutSectionProps } from '../../Components/AboutSection/AboutSectionLayout';
import { ProfessionalExperienceSectionLayout } from '../../Components/ProfessionalExperienceSection/ProfessionalExperienceSectionLayout';
import { EducationPanelLayout, Props as EducationPanelProps } from '../../Components/EducationPanel/EducationPanelLayout';
import { schema as educationSchema } from '../../Schema/CVs/default/Education';
import { cssVarColors } from '../../Constants';
import { useSearchParams } from 'react-router-dom';
import { CvFilterType } from '../../Types/cvFilterType';

export type Props = {};

export const CvLayout = (props: Props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const render = () => {
		let filter = searchParams.get('filter');

		const educationProps: EducationPanelProps = {
			title: educationSchema.title,
			backgroundColor: cssVarColors.onSurface,
			textColor: '#fff',
			lines: educationSchema.lines,
		};
		const aboutSectionProps: AboutSectionProps = {
			filter: filter as CvFilterType,
		};
		const returnValue = (
			<CvWrapper>
				<AboutSectionLayout {...aboutSectionProps} />
				<BodySection>
					<ProfessionalExperienceSectionLayout />
					<EducationPanelLayout {...educationProps} />
				</BodySection>
			</CvWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
