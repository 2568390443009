import React from 'react';
import { RouteType } from './types';
import { SitemapContainer } from '../../Pages/Sitemap/SitemapContainer';
import { SitemapLayout } from '../../Pages/Sitemap/SitemapLayout';
import { CVRoutes } from './helpers';
import { PortfolioRoutes } from './helpers';
import { ComingSoonContainer } from '../../Pages/ComingSoon/ComingSoonContainer';
import { ComingSoonLayout } from '../../Pages/ComingSoon/ComingSoonLayout';

export const productionRoutes: Array<RouteType> = [
	{
		path: '/',
		element: <ComingSoonContainer Layout={ComingSoonLayout} />,
	},
	{
		path: '/sitemap',
		element: <SitemapContainer Layout={SitemapLayout} />,
	},
	...CVRoutes,
	...PortfolioRoutes,
];

export const routes = productionRoutes;
