import { styled } from '@mui/material/styles';
import { Link } from '@mui/material';
import { cssVarColors } from '../../Constants';
import { mobileThreshold, portfolioBackground, portfolioWidth } from '../../Constants/layoutValues';

export const PortfolioWrapper = styled('div')`
	background-color: ${portfolioBackground};
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin-top: 0;
	padding-top: 4rem;
	min-width: 100vw;
	max-width: 100vw;
	flex-grow: 1;
	padding-bottom: 2rem;
	position: relative;
	@media (max-width: ${mobileThreshold}px) {
		padding-left: 0;
		padding-right: 0;
		padding-top: 2rem;
	}
`;

export const ContentWrapper = styled('div')`
	flex-direction: row;
	max-width: ${portfolioWidth};
	// width: ${portfolioWidth};
	display: flex;
	padding-left: 2rem;
	padding-right: 2rem;
	@media (max-width: ${mobileThreshold}px) {
		width: 100%;
		max-width: 100%;
		flex-direction: column;
	}
`;

export const Lane = styled('div')`
	display: flex;
	overflow: hidden;
	flex-direction: column;
	padding-left: 1rem;
	padding-right: 1rem;
	gap: 2rem;
	background-color: ${portfolioBackground};
	:last-of-type {
		padding-right: 0;
	}
	:first-of-type {
		padding-left: 0;
	}
	@media (max-width: ${mobileThreshold}px) {
		padding-left: 0rem;
		padding-right: 0rem;
		width: 100%;
	}
`;

export const LaneOne = styled(Lane)`
	flex: 8;
	@media (max-width: ${mobileThreshold}px) {
		flex: none;
	}
`;

export const LaneTwo = styled(Lane)`
	flex: 3;
	@media (max-width: ${mobileThreshold}px) {
		flex: none;
		padding-bottom: 2rem;
		margin-top: -2rem;
	}
`;

export const UseCaseSectionWrapper = styled('div')`
	flex: 1;
	align-self: stretch;
`;

export const UseCaseSectionTitle = styled('div')`
	font-family: montserratItalics;
	font-size: 1.15rem;
`;

export const UseCaseSectionContent = styled('div')`
	padding-left: 0.5rem;
`;

export const UseCaseListingWrapper = styled(Link)`
	display: flex;
	flex-direction: column;
	text-decoration: none;
	color: #fff;
	line-height: 1.8rem;
	:hover {
		color: ${cssVarColors.highlight};
	}
`;
