import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { cssVarColors } from '../Constants';

export const SpanWrapper = styled('span')``;

export const LinkWrapper = styled(Link)`
	text-decoration: none;
	color: ${cssVarColors.primary};
	cursor: pointer;
	:hover {
		color: ${cssVarColors.highlight};
	}
`;

type ConvertTextToLinksProps = {
	text: string;
};

export const convertTextToLinks: React.FC<ConvertTextToLinksProps> = ({ text }): ReactElement => {
	const regex = /<href='([^']*)'>([^<]*)<\/href>/g;

	const parts: ReactElement[] = [];
	let match: RegExpExecArray | null;
	let lastIndex = 0;

	while ((match = regex.exec(text)) !== null) {
		const precedingText = text.slice(lastIndex, match.index);
		if (precedingText.trim() !== '') {
			const content = React.createElement(SpanWrapper, { key: `span-${lastIndex}` }, precedingText);
			parts.push(content);
		}
		parts.push(React.createElement(LinkWrapper, { key: `link-${match.index}`, to: match[1] }, match[2]));
		lastIndex = regex.lastIndex;
	}

	const remainingText = text.slice(lastIndex);
	if (remainingText.trim() !== '') {
		const content = React.createElement(SpanWrapper, { key: `span-${lastIndex}` }, remainingText);
		parts.push(content);
	}

	return React.createElement(React.Fragment, null, parts);
};
