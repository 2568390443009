import { UseCaseType } from '../../../Types/Portfolio/UseCaseType';
import { Bamba } from '../Employment/Bamba';

export const useCase: UseCaseType = {
	title: '#Partner Configuration',
	image: 'https://assets.dear-ux.com/faizhirani/images/Chowbotics Architecture.png',
	employment: Bamba,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ['After joining The Helper Bees, I noticed each of our 15 partners had a dedicated tech stack, with a React frontend and Django backend running on a Google Cloud VM. All dashboards ran the same core code, pivoting on environment variables.', 'With 15 partners came 15 sets of customized features, visualizations, field names, and more. This led to two significant issues:', ['Custom features for each partner resulted in a proliferation of conditional statements scattered throughout both the front and back end.', 'Even minor customizations couldn’t be deployed immediately — they had to wait for the biweekly release cycle, significantly slowing down responsiveness.'], 'This was poor Developer Experience (DX), as the countless conditionals increased cognitive load and made the code hard to manage. It also reduced agility — deploying simple changes every two weeks is incredibly slow in a fast-paced environment.', 'I set out to fix these problems while familiarizing myself with the codebase.'],
		},
		{
			sectionTitle: 'Solution',
			paras: ["I overhauled the entire codebase by abstracting all conditional statements into a set of configuration files, which were further abstracted into Google Cloud's NoSQL database. I then created a service to dynamically deliver these configuration files to each dashboard."],
		},
		{
			sectionTitle: 'Impact',
			paras: ['As expected, the overhaul had a profound impact:', ['Improved DX: The streamlined codebase made reading, extending, and refactoring significantly easier, reducing the complexity and cognitive load of scattered conditionals.', 'Increased agility: Abstracting customizations to the NoSQL database enabled instant updates without deployments, entirely eliminating the two-week lag.']],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ['The approach taken at The Helper Bees showed how quickly a codebase can spiral out of control when built for only immediate needs. The original code was developed for a single partner, but as more partners requested customizations, conditionals were added without refactoring for future growth, causing technical debt to pile up faster than it could be addressed.', 'Had the team invested time in building a flexible foundation from the start, later iterations could have been handled more smoothly, allowing new partners to launch faster.', 'By the time I joined, this technical debt had become a significant bottleneck. Fortunately, with my comfort in working with abstractions, refactoring did not require much cognitive load, and I was able to integrate it into my initial familiarization with the system.'],
		},
	],
};
