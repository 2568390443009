import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, RouteProps } from 'react-router-dom';
import { routes } from './Schema/Routes/general';
import { RouteType } from './Schema/Routes/types';
import ReactGA from 'react-ga';
import { googleID } from './Constants/env';
import { setWindowWidth, setWindowHeight } from './ReduxApp/app/consts/duck/operations';
import { useDispatch } from 'react-redux';

const generateRoutes = (routes: Array<RouteType>) => {
	const returnValue = routes.map((route, index: number) => {
		const outgoingProps: RouteProps = {
			path: route.path,
			//@ts-ignore
			element: route.element,
		};
		const innerReturnValue = <Route key={'route' + index} {...outgoingProps} />;
		return innerReturnValue;
	});
	return returnValue;
};

const calculateWidth = () => {
	const returnValue = window.innerWidth < window.outerWidth ? window.innerWidth : window.outerWidth;
	return returnValue;
};

const calculateHeight = () => {
	const returnValue = window.innerHeight < window.outerHeight ? window.innerHeight : window.outerHeight;
	return returnValue;
};

export const App = () => {
	ReactGA.initialize(googleID, { debug: false });
	ReactGA.pageview(window.location.pathname + window.location.search);
	const dispatch = useDispatch();
	const handleWindowSizeChange = () => {
		dispatch(setWindowWidth(calculateWidth()));
		dispatch(setWindowHeight(calculateHeight()));
	};

	useEffect(() => {
		dispatch(setWindowWidth(calculateWidth()));
		dispatch(setWindowHeight(calculateHeight()));
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const returnValue = (
		<Router>
			<Routes>{generateRoutes(routes)}</Routes>
		</Router>
	);
	return returnValue;
};
