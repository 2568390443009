import * as React from 'react';
import { SkillType } from '../../../Types/SkillType';
import { TechnologyType } from '../../../Types/TechnologyType';
import { CvFilterType } from '../../../Types/cvFilterType';

export type Props = {
	lines: Array<SkillType | TechnologyType>;
	filter: CvFilterType;
};

export const generateMeritsLineItems = (props: Props) => {
	const returnValueArray: Array<string> = [];
	props.lines.forEach((line: SkillType | TechnologyType) => {
		let show;
		if (line.showOn) {
			const showOnAll = line.showOn.indexOf('all') !== -1;
			const showOnFilter = line.showOn.indexOf(props.filter) !== -1;
			show = showOnFilter || showOnAll;
		} else show = true;
		if (show) returnValueArray.push(line.entry);
	});
	const returnValue = returnValueArray.join(', ');
	return returnValue;
};
