import styled from 'styled-components';
import { mobileThreshold } from '../../Constants/layoutValues';

export const AboutSectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	font-size: 0.95em;
	margin-bottom: -1rem;
	background-color: #001c4c;
	color: #fff;
	min-height: 100vh;
	min-width: 270px;
	max-width: 300px;
	print-color-adjust: exact;
	padding-left: 1rem;
	padding-right: 1rem;
	@media print {
		background-color: #001c4c;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		min-width: 125px;
		max-width: 125px;
		padding-bottom: 0.25em;
		font-size: 0.5rem;
	}
	@media (max-width: ${mobileThreshold}px) {
		padding-left: 0rem;
		padding-right: 0rem;
		flex-direction: column;
	} ;
`;

export const ContactContentWrapper = styled('div')`
	padding-left: 1rem;
`;
