export const schema = {
	title: 'About Me',
	quote: '"I will never climb a mountain... there has to be a better way."',
	intro: 'Software Engineer with 15+ years of experience prioritizing agility to position companies to capitalize on market opportunities as they arise.',
	bullets: [
		// 'Positioning companies to capitalize on market opportunities as they arise',
		// 'Prioritizing agility in the of the software team in order to react to changing company needs',
		// "Diligently building resilient, scalable, robust solutions today, to make better someone's tomorrow",
		// 'Ensuring distracted key stakeholders have the data needed to make informed decisions',
		// 'Advocating that optimizing developer experience is the best way to influence product development',
		// "Wondering why User Experience isn't prioritized the same in Tech as in Michelin Star restaurants",
		// "Diligently building resilient, scalable, robust solutions today, to make better someone's tomorrow"
	],
};
