import React from "react";

export const SNACKBAR_SUCCESS = "SNACKBAR_SUCCESS";
export const SNACKBAR_CLEAR = "SNACKBAR_CLEAR";

export type SNACKBAR_SUCCESS_TYPE = {
	type: typeof SNACKBAR_SUCCESS,
	 payload: {
	 	message: string | React.ReactNode
	 }
}

export type SNACKBAR_CLEAR_TYPE = {
	type: typeof SNACKBAR_CLEAR,
};

export type SnackbarActionTypes = SNACKBAR_SUCCESS_TYPE
	| SNACKBAR_CLEAR_TYPE;

export const ActionNames = {
	SNACKBAR_CLEAR,
	SNACKBAR_SUCCESS
}
