import styled from 'styled-components';
import { mobileThreshold } from '../../Constants/layoutValues';

export const BulletListAndIntroWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	@media (max-width: ${mobileThreshold}px) {
		margin-right: 0;
	} ;
`;

export const IntroWrapper = styled('div')`
	padding-left: 0;
	padding-right: 0;
`;

export const BulletListWrapper = styled('ul')`
	margin-top: 0;
`;

export const BulletPointWrapper = styled('li')`
	padding-top: 0.1em;
	padding-bottom: 0.1em;
	:last-of-type {
		padding-bottom: 0;
	}
`;
