import * as React from 'react';
import { Company, DateWrapper, Location, Spacer, Title, TitleLine, UnorderedList, EntryContent, EntryColumnHeadingWrapper, DateColumnWrapper, DetailsWrapper, EntryWrapperEven, EntryWrapperOdd, EmploymentDetails, TimespanWrapper } from '../ProfessionalExperienceSectionStyles';
import { ProfessionalExperienceEntriesType, ProfessionalExperienceEntryType, ProfessionalExperienceLineItemType } from '../../../Types/ProfessionalExperienceLineType';
import { EmployeeNumberLayout, Props as EmployeeNumberProps } from './EmployeeNumberLayout';
import { MajorAccomplishmentLayout, Props as MajorAccomplishmentProps } from './MajorAccomplishmentLayout';
import { ResultLayout, Props as ResultProps } from './ResultLayout';
import { LengthLayout, Props as LengthProps } from './LengthLayout';
import { DescriptionLayout, Props as DescriptionProps } from './DescriptionLayout';
import { TechnologiesLayout, Props as TechnologiesProps } from './TechnologiesLayout';
import { ToolsLayout, Props as ToolsProps } from './ToolsLayout';

export type Props = {
	entry: ProfessionalExperienceEntryType;
	subtype: string;
	odd: boolean;
};

export const ProfessionalExperienceEntry = (props: Props) => {
	const render = () => {
		const emptyJSX = null;
		const EntryWrapper = props.odd ? EntryWrapperEven : EntryWrapperOdd;
		const lengthProps: LengthProps = {
			length: props.entry.length,
		};
		const employeeNumberProps: EmployeeNumberProps = {
			number: props.entry.employeeNumber ? props.entry.employeeNumber.number : null,
			type: props.entry.employeeNumber ? props.entry.employeeNumber.type : null,
		};

		const majorAccomplishmentProps: MajorAccomplishmentProps = {
			majorAccomplishments: props.entry.majorAccomplishment ? props.entry.majorAccomplishment : null,
		};

		const resultsProps: ResultProps = {
			results: props.entry.results ? props.entry.results : null,
		};
		const descriptionProps: DescriptionProps = {
			description: props.entry.description,
		};

		const technologiesProps: TechnologiesProps = {
			technologies: props.entry.technologies,
		};

		const toolsProps: ToolsProps = {
			tools: props.entry.tools,
		};

		const resultJSX = <ResultLayout {...resultsProps} />;
		const employeeJSX = <EmployeeNumberLayout {...employeeNumberProps} />;
		const majorAccomplishmentJSX = <MajorAccomplishmentLayout {...majorAccomplishmentProps} />;
		const descriptionJSX = <DescriptionLayout {...descriptionProps} />;
		const lengthJSX = <LengthLayout {...lengthProps} />;
		const technologiesJSX = <TechnologiesLayout {...technologiesProps} />;
		const toolsJSX = <ToolsLayout {...toolsProps} />;
		const timespan = props.entry.timespan.split(';').map((entry) => <TimespanWrapper>{entry}</TimespanWrapper>);
		console.log('timespan is', timespan);
		const entryJSX = (
			<EntryWrapper>
				<DateColumnWrapper>
					<DateWrapper>{timespan}</DateWrapper>
					<EmploymentDetails>
						{descriptionJSX}
						{lengthJSX}
						{employeeJSX}
						{majorAccomplishmentJSX}
						{resultJSX}
					</EmploymentDetails>
				</DateColumnWrapper>
				<EntryColumnHeadingWrapper>
					<TitleLine>
						<Title>{props.entry.title}</Title>
						<Spacer />
						<Company>{props.entry.company}</Company>
						<Spacer />
						<Location>{props.entry.location}</Location>
					</TitleLine>
					{technologiesJSX}
					{toolsJSX}
					<DetailsWrapper>
						<UnorderedList>{generateDetails(props.entry.details, props.subtype)}</UnorderedList>
					</DetailsWrapper>
				</EntryColumnHeadingWrapper>
			</EntryWrapper>
		);
		const returnValue = shouldIShow(props.entry.showMeOn, props.subtype) ? entryJSX : emptyJSX;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

export const shouldIShow = (showMeOn: Array<string>, subtype: string) => {
	let returnValue = false;
	if (showMeOn.indexOf('all') !== -1) returnValue = true;
	if (showMeOn.indexOf(subtype) !== -1) returnValue = true;
	return returnValue;
};

export const generateDetails = (details: Array<ProfessionalExperienceLineItemType>, subtype: string) => {
	const returnValue = details.map((lineItem) => {
		const shouldIShowBoolean = shouldIShow(lineItem.showMeOn, subtype);
		let conditionalReturnValue: any = null;
		if (shouldIShowBoolean) {
			const entryJSX = <EntryContent>{lineItem.entry}</EntryContent>;
			conditionalReturnValue = entryJSX;
			if (lineItem.children) {
				const childrenJSX = <UnorderedList>{generateDetails(lineItem.children, subtype)}</UnorderedList>;
				conditionalReturnValue = [entryJSX, childrenJSX];
			}
		}
		const innerReturnValue = shouldIShowBoolean ? conditionalReturnValue : null;
		return innerReturnValue;
	});
	return returnValue;
};
