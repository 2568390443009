import { styled } from '@mui/material/styles';
import { Link } from '@mui/material';
import { cssVarColors } from '../../../../Constants';
import { mobileThreshold } from '../../../../Constants/layoutValues';

export const ContactSectionWrapper = styled('div')`
	@media (max-width: ${mobileThreshold}px) {
		margin-top: 2rem;
	}
`;

export const ContactLineItemWrapper = styled('div')`
	display: flex;
	flex-direction: row;
`;

export const IconWrapper = styled('div')`
	padding-right: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
`;

export const TextWrapper = styled('div')``;

export const LinkWrapper = styled(Link)`
	text-decoration: none;
	color: ${cssVarColors.primaryLight};
	:hover {
		color: ${cssVarColors.highlight};
	}
`;
