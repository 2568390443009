import { CoreCompetency, Props as CoreCompetencyProps } from '../Layouts/CoreCompetency';
import React from 'react';

export const generateCoreCompetencies = (cores: string[]) => {
	const returnValue = cores.map((core: string) => {
		const outgoingProps: CoreCompetencyProps = {
			coreCompetency: core,
		};
		const innerReturnValue = <CoreCompetency {...outgoingProps} />;
		return innerReturnValue;
	});
	return returnValue;
};
