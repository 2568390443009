import { ListItem, UnorderedList, UseCasePara } from '../UseCaseStyles';
import * as React from 'react';
import { convertTextToLinks } from '../../../utils/convertTextToLinks';

export const generateParagraphs = (body: Array<string | Array<string>>, columns: number = 1) => {
	const returnValue = body.map((para: string | Array<string>) => {
		let innerReturnValue = null;
		if (Array.isArray(para)) {
			const listContent = para.map((listItem: string) => {
				const nestedReturnValue = <ListItem>{listItem}</ListItem>;
				return nestedReturnValue;
			});
			innerReturnValue = <UnorderedList style={{ columns: columns }}>{listContent}</UnorderedList>;
		} else {
			const outgoingProps = {
				text: para,
			};
			const modifiedPara = convertTextToLinks(outgoingProps);
			innerReturnValue = <UseCasePara>{modifiedPara}</UseCasePara>;
		}
		return innerReturnValue;
	});
	return returnValue;
};
