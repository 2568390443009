import * as React from 'react';
import { EmployeeNumberWrapper } from '../ProfessionalExperienceSectionStyles';

export type Props = {
	number: number | null;
	type: string | null;
};

export const EmployeeNumberLayout = (props: Props) => {
	const render = () => {
		const employeeJSX = <EmployeeNumberWrapper>Employee number ~{props.number}</EmployeeNumberWrapper>;
		const returnValue = props.number !== null ? employeeJSX : null;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
