import * as React from 'react';
import { SectionPara, SectionTitle, SectionWrapper } from '../UseCaseStyles';
import { generateParagraphs } from '../helpers/generateParagraphs';
import { UseCaseSection } from '../../../Types/Portfolio/UseCaseSection';
import { useMobile } from '../../../utils/useMobile';

export type Props = {
	section: UseCaseSection;
};

export const SectionLayout = (props: Props) => {
	const isMobile = useMobile();
	const render = () => {
		const columns = props.section.columns && !isMobile ? props.section.columns : 1;
		const paras = generateParagraphs(props.section.paras, columns);
		const returnValue = (
			<SectionWrapper>
				<SectionTitle>{props.section.sectionTitle}</SectionTitle>
				<SectionPara>{paras}</SectionPara>
			</SectionWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
