import { styled } from '@mui/material/styles';
import { cssVarColors } from '../../Constants';

export const PaneWrapper = styled('div')`
	background-color: ${cssVarColors.surface};
	padding-top: 1.5rem;
	padding-bottom: 2rem;
	padding-left: 2rem;
	padding-right: 2rem;
	width: 100%;
`;

export const PaneTitle = styled('div')`
	font-family: montserratSemiBold;
	font-size: 1.2rem;
	padding-bottom: 1rem;
`;

export const PaneBody = styled('div')``;
