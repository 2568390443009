import * as React from 'react';
import { EducationItemWrapper, EducationDegreeWrapper, EducationUniversityWrapper, EducationLocationWrapper, EducationTimespanWrapper } from '../EducationPanelStyles';
import { EducationEntryType } from '../../../Types/EducationType';

export type Props = {
	entry: EducationEntryType;
};

export const EducationItemLayout = (props: Props) => {
	const render = () => {
		const schoolJSX = !props.entry.school ? null : <EducationUniversityWrapper>{props.entry.school},</EducationUniversityWrapper>;
		const returnValue = (
			<EducationItemWrapper>
				<EducationDegreeWrapper>{props.entry.degree},</EducationDegreeWrapper>
				{schoolJSX}
				<EducationLocationWrapper>{props.entry.location} | </EducationLocationWrapper>
				<EducationTimespanWrapper>{props.entry.timespan}</EducationTimespanWrapper>
			</EducationItemWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
