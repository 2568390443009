import * as React from 'react';
import { Props as LayoutProps } from './UseCaseLayout';
import { useQuery } from '../../utils/useQuery';
import { useCases } from '../../Schema/Portfolio/useCases';
import { UseCaseType } from '../../Types/Portfolio/UseCaseType';

export type Props = {
	Layout: any;
};

export const UseCaseContainer = (props: Props) => {
	const queryParams = useQuery();
	const render = () => {
		const id = queryParams.get('id');
		let useCase: UseCaseType | null = null;
		let previousUseCaseID = null;
		let nextUseCaseID = null;
		for (let i = 0; i < useCases.length; i++) {
			const candidateUseCase = useCases[i];
			if (candidateUseCase.id === id) {
				if (i > 0) previousUseCaseID = useCases[i - 1].id;
				useCase = candidateUseCase.content;
				if (i < useCases.length - 1) nextUseCaseID = useCases[i + 1].id;
			}
		}

		const Layout = props.Layout;
		const outgoingProps: LayoutProps = {
			nextUseCaseID,
			previousUseCaseID,
			useCase: useCase as UseCaseType,
		};
		const returnValue = useCase ? <Layout {...outgoingProps} /> : null;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
