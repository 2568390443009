import React from 'react';
import { RouteType } from '../types';
import { PortfolioContainer } from '../../../Pages/Portfolio/PortfolioContainer';
import { PortfolioLayout } from '../../../Pages/Portfolio/PortfolioLayout';
import { UseCaseContainer } from '../../../Pages/UseCase/UseCaseContainer';
import { UseCaseLayout } from '../../../Pages/UseCase/UseCaseLayout';

export const routes: Array<RouteType> = [
	{
		path: '/portfolio/',
		element: <PortfolioContainer Layout={PortfolioLayout} />,
	},
	{
		path: '/portfolio/useCase',
		element: <UseCaseContainer Layout={UseCaseLayout} />,
	},
];
