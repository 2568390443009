import { UseCaseType } from '../../../Types/Portfolio/UseCaseType';
import { Chowbotics } from '../Employment/Chowbotics';

export const useCase: UseCaseType = {
	title: '#Bowl Counter',
	video: 'https://assets.dear-ux.com/faizhirani/images/100 thousand bowls.mov',
	employment: Chowbotics,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ["During a discussion, one of our investors  mentioned that it may be beneficial for our employees to understand the impact they are having on people's lives in real time.", 'This resonated with me and I decided to take the initiative and design a solution.'],
		},
		{
			sectionTitle: 'Solution Implemented',
			paras: ["I created a Bowl Counter Dashboard that displayed the total number of bowls sold, the number sold today, and a set of graphs comparing today's total to our average and expected sales. It also featured a brief, attention-grabbing animation each time a bowl was sold."],
		},
		{
			sectionTitle: 'Impact',
			paras: ['The Bowl Counter Dashboard was enthusiastically received and prominently featured on every office TV for 1.5 years, until Covid altered our working circumstances. Several colleagues shared that they loved the bursts of dopamine the animation provided throughout the day.', 'When we approached and surpassed 100,000 bowls sold, everyone gathered around the Dashboard in a celebratory mood—with a gong to mark the occasion.'],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ["Chowbotics' network infrastructure, which I designed for agility, was distributed, event-driven, and highly decoupled. This made it easy to tap into sale events broadcast by the robots, transform the data, and store it in a Redshift OLAP table. Building a simple API and front end to visualize the Redshift table was straightforward."],
		},
	],
};
