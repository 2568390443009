import * as React from 'react';
import { AboutMeWrapper } from './AboutMeStyles';
import { language } from './AboutMeLanguage';
import { PaneLayout, Props as PaneProps } from '../../../../Components/Pane/PaneLayout';

export type Props = {};

export const AboutMeLayout = (props: Props) => {
	const render = () => {
		const outgoingProps: PaneProps = {
			title: language.title,
			body: language.body,
		};
		const returnValue = (
			<AboutMeWrapper>
				<PaneLayout {...outgoingProps} />
			</AboutMeWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
