export const schema = {
	title: 'Contact Info',
	lines: [
		{
			icon: ['fas', 'phone'],
			text: '1 (647) 998-5102',
		},
		{
			icon: ['fas', 'envelope'],
			text: 'faizhirani@gmail.com',
		},
		{
			icon: ['fas', 'location-dot'],
			text: ['Toronto, Ontario', 'Canada'],
		},
		{
			icon: ['fab', 'linkedin'],
			text: 'LinkedIn',
			href: 'https://www.linkedin.com/in/fahirani/',
		},
		{
			icon: ['fas', 'briefcase'],
			text: 'Portfolio',
			href: 'https://www.faizhirani.com/portfolio',
		},
	],
};
