export const cssVarColors = {
	onSurface: 'rgb(152, 168, 190)',
	primary: '#57AAF7',
	primaryLight: '#BBDDFB',
	highlight: '#B189F5',
	highlightAlternate: '',
	highlightAlpha: '#571B9A08',
	surface: 'rgb(52, 68, 90)',
	surfaceAlternate: '#001c4c',
};
