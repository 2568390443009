import * as React from 'react';
import { MeritsContentWrapper } from '../MeritPanelStyles';
import { generateMeritsLineItems, Props as GenerateProps } from '../helpers/generateMeritsLineItems';
import { SkillType } from '../../../Types/SkillType';
import { TechnologyType } from '../../../Types/TechnologyType';
import { CvFilterType } from '../../../Types/cvFilterType';

export type Props = {
	lines: Array<SkillType | TechnologyType>;
	filter: CvFilterType;
};

export const MeritsContent = (props: Props) => {
	const render = () => {
		const generateProps: GenerateProps = {
			lines: props.lines ? props.lines : [],
			filter: props.filter,
		};
		const returnValue = <MeritsContentWrapper>{generateMeritsLineItems(generateProps)}</MeritsContentWrapper>;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
