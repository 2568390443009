import { styled } from '@mui/material/styles';
import { mobileThreshold } from '../../Constants/layoutValues';

export const HeaderNav = styled('div')`
	display: flex;
	flex-direction: row;
	padding-top: 3rem;
	padding-bottom: 1rem;
`;

export const ImageWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-bottom: 2rem;
	padding-top: 2rem;
	flex: 1;
	//height: 500px;
	@media (max-width: ${mobileThreshold}px) {
		max-width: 100%;
	}
`;

export const Image = styled('img')`
	justify-content: center;
	align-items: center;
	display: flex;
	align-self: center;
	justify-self: center;
	max-height: 90%;
	max-width: 90%;
`;

export const VideoWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-bottom: 2rem;
	padding-top: 2rem;
	flex: 1;
`;

export const Video = styled('video')`
	justify-content: center;
	align-items: center;
	display: flex;
	align-self: center;
	justify-self: center;
	max-height: 90%;
	max-width: 90%;
`;

export const UseCaseContainer = styled('div')`
	color: #fff;
	background-color: #000;
	min-height: 100vh;
	width: 100vw;
	display: flex;
	align-items: flex-start;
	justify-content: center;
`;

export const UseCaseWrapper = styled('div')`
	padding-left: 1rem;
	padding-right: 1rem;
	max-width: 900px;
	@media (max-width: ${mobileThreshold}px) {
	}
`;

export const UseCaseTitle = styled('div')`
	padding-top: 1rem;
	padding-bottom: 1rem;
	font-size: 2rem;
`;

export const UseCaseBody = styled('div')`
	font-size: 1.15rem;
	line-height: 1.75rem;
	display: flex;
	flex-direction: column;
`;

export const UseCasePara = styled('div')`
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	:last-of-type {
		padding-bottom: 0;
	}
	:first-of-type {
		padding-top: 0;
		padding-bottom: 0.5rem;
	}
`;

export const UnorderedList = styled('ul')`
	margin-top: 0;
	margin-bottom: 0.25rem;
`;

export const ListItem = styled('li')``;

export const SectionWrapper = styled('div')`
	padding-top: 1rem;
`;

export const SectionTitle = styled('div')`
	font-size: 1.5rem;
	padding-bottom: 0.25rem;
`;

export const SectionPara = styled('div')`
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
`;

export const FooterNav = styled('div')`
	padding-top: 1rem;
	display: flex;
	flex-direction: row;
	padding-bottom: 3rem;
`;
