import { UseCaseSection } from '../../../Types/Portfolio/UseCaseSection';
import { Props as SectionLayoutProps, SectionLayout } from '../Layouts/SectionLayout';
import * as React from 'react';

export const generateSections = (sections: UseCaseSection[]) => {
	const returnValue = sections.map((section: UseCaseSection) => {
		const sectionLayoutProps: SectionLayoutProps = {
			section,
		};
		const innerReturnValue = <SectionLayout {...sectionLayoutProps} />;
		return innerReturnValue;
	});
	return returnValue;
};
