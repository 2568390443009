import * as React from 'react';
import { FooterNav, HeaderNav, ImageWrapper, UseCaseBody, UseCaseTitle, UseCaseWrapper, Image, Video, VideoWrapper, UseCaseContainer } from './UseCaseStyles';
import { language } from './UseCaseLanguage';
import { NavButtonLayout, Props as NavButtonProps } from '../../Components/NavButton/NavButtonLayout';
import { UseCaseType } from '../../Types/Portfolio/UseCaseType';
import { generateSections } from './helpers/generateSections';
import { HeaderLayout } from './Layouts/HeaderLayout';
import { GeneralViewPortLayout } from '../../Components/GeneralViewPort/GeneralViewPortLayout';

export type Props = {
	useCase: UseCaseType;
	nextUseCaseID?: string | null;
	previousUseCaseID?: string | null;
};

export const UseCaseLayout = (props: Props) => {
	const render = () => {
		const backButtonProps: NavButtonProps = {
			icon: language.backButtonIcon,
			label: language.backButtonLabel,
			href: '/portfolio',
		};
		const nextButtonProps: NavButtonProps = {
			icon: language.nextButtonIcon,
			label: language.nextButtonLabel,
			href: '/portfolio/useCase?id=' + props.nextUseCaseID,
			alignRight: true,
		};
		const previousButtonProps: NavButtonProps = {
			icon: language.previousButtonIcon,
			label: language.previousButtonLabel,
			href: '/portfolio/useCase?id=' + props.previousUseCaseID,
		};

		const previousButton = !props.previousUseCaseID ? null : <NavButtonLayout {...previousButtonProps} />;
		const nextButton = !props.nextUseCaseID ? null : <NavButtonLayout {...nextButtonProps} />;
		const sections = generateSections(props.useCase.body);
		const headerProps = {
			useCase: props.useCase,
		};
		const imageProps = {
			src: props.useCase.image,
		};
		const imageSection = !props.useCase.image ? null : (
			<ImageWrapper>
				<Image {...imageProps} />
			</ImageWrapper>
		);

		const videoSection = !props.useCase.video ? null : (
			<VideoWrapper>
				<Video controls>
					<source src={props.useCase.video} type="video/mp4" />
				</Video>
			</VideoWrapper>
		);

		const contentJSX = (
			<UseCaseContainer>
				<UseCaseWrapper>
					<HeaderNav>
						<NavButtonLayout {...backButtonProps} />
					</HeaderNav>
					<HeaderLayout {...headerProps} />
					<UseCaseTitle>{props.useCase.title}</UseCaseTitle>
					{imageSection}
					{videoSection}
					<UseCaseBody>{sections}</UseCaseBody>
					<FooterNav>
						{previousButton}
						{nextButton}
					</FooterNav>
				</UseCaseWrapper>
			</UseCaseContainer>
		);
		const returnValue = <GeneralViewPortLayout internalJSX={contentJSX} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
