import styled, { keyframes } from 'styled-components';
import { mobileThreshold, portfolioWidth } from '../../Constants/layoutValues';
import { cssVarColors } from '../../Constants';
import { Link } from '@mui/material';

export const PersistantHeaderWrapper = styled('div')`
	height: 200px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: ${cssVarColors.surfaceAlternate};
	color: #fff;

	@media (max-width: calc(${portfolioWidth} + 4rem)) {
		padding-left: 2rem;
	}

	@media (max-width: ${mobileThreshold}px) {
		padding-left: 0;
		padding-bottom: 1rem;
	}
`;

export const Wrapper = styled('div')`
	max-width: ${portfolioWidth};
	width: min(100%, ${portfolioWidth});
	@media (max-width: ${mobileThreshold}px) {
		padding-left: 2rem;
	}
`;

export const NameWrapper = styled('div')`
	font-size: 3rem;
`;

export const Subtitle = styled('div')`
	display: flex;
	flex-direction: column;
	font-size: 1.5rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
`;

export const SubtitleLine = styled('div')`
	:last-of-type {
		padding-top: 0.25rem;
	}
`;

export const SocialLinksWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	padding-top: 0.5rem;
	font-size: 1.5rem;
`;

export const Icon = styled(Link)`
	color: #fff;
`;

export const IconWrapper = styled('div')`
	background-color: ${cssVarColors.onSurface};
	border-radius: 50%;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	:hover {
		background-color: #fff;
	}
	&:hover ${Icon} {
		color: ${cssVarColors.highlight};
	}
`;
