import { TechnologyType } from '../../../Types/TechnologyType';
import { ToolsType } from '../../../Types/ToolsType';

export type SchemaType = {
	title: string;
	lines: Array<ToolsType>;
};

export const schema: SchemaType = {
	title: 'Tools',
	lines: [
		{
			entry: 'AWS CDK',
		},
		{
			entry: 'AWS SDK',
		},
		{
			entry: 'AWS CLI',
		},
		{
			entry: 'Terraform',
		},
		{
			entry: 'Ansible',
		},
		{
			entry: 'Docker',
		},
		{
			entry: 'Kubernetes',
		},
		{
			entry: 'Postman',
		},
		{
			entry: 'Swagger',
		},
		{
			entry: 'Jest',
		},
		{
			entry: 'Cypress',
		},
		{
			entry: 'Jira',
		},
		{
			entry: 'Trello',
		},
		{
			entry: 'Slack',
		},
		{
			entry: 'Notion',
		},
		{
			entry: 'Excalidraw',
		},
		{
			entry: 'InVision',
		},
		{
			entry: 'Git',
		},
		{
			entry: 'CircleCI',
		},
		{
			entry: 'VSCode',
		},
		{
			entry: 'Webstorm',
		},
		{
			entry: 'JetBrains',
		},
		{
			entry: 'Cursor IO',
		},
		{
			entry: 'Twilio',
		},
		{
			entry: 'Tableau',
		},
		{
			entry: 'Power BI',
		},
		{
			entry: 'Hootsuite',
		},
		{
			entry: 'Stable Diffusion',
		},
		{
			entry: 'Blender',
		},
		{
			entry: 'Adobe Photoshop',
		},
	],
};
