import * as React from 'react';
import { ProfessionalExperienceSectionWrapper, ProfessionalSectionEntriesWrapper } from './ProfessionalExperienceSectionStyles';
import { language } from './ProfessionalExperienceSectionLanguage';
import { Props as SectionTitleProps, SectionTitleLayout } from '../SectionTitle/SectionTitleLayout';
import { ProfessionalExperienceEntriesType, ProfessionalExperienceEntryType } from '../../Types/ProfessionalExperienceLineType';
import { schema as ProfessionalExperienceSchema } from '../../Schema/CVs/default/ProfessionalExperienceSchema';
import { schema as ProfessionalExperienceSchemaNew } from '../../Schema/CVs/default/ProfessionalExperienceSchemaNew';
import { ProfessionalExperienceEntry, Props as ProfessionalExperienceEntryProps } from './Layouts/ProfessionalExperienceEntry';
import { useSearchParams } from 'react-router-dom';

export type Props = {};

export const ProfessionalExperienceSectionLayout = (props: Props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const render = () => {
		let stupidTempVar = searchParams.get('subtype') ? searchParams.get('subtype') : searchParams.get('subType');

		const subtype = stupidTempVar ? stupidTempVar : 'standard';
		const sectionTitleProps: SectionTitleProps = {
			text: language.title,
			fullWidth: true,
		};
		const professionsExperienceEntries = generateProfessionalExperienceEntries(subtype, ProfessionalExperienceSchemaNew);

		const returnValue = (
			<ProfessionalExperienceSectionWrapper>
				<SectionTitleLayout {...sectionTitleProps} />
				<ProfessionalSectionEntriesWrapper>{professionsExperienceEntries}</ProfessionalSectionEntriesWrapper>
			</ProfessionalExperienceSectionWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const generateProfessionalExperienceEntries = (subtype: string, entries: ProfessionalExperienceEntriesType) => {
	const returnValue = entries.map((theEntry: ProfessionalExperienceEntryType, index: number) => {
		const lineProps: ProfessionalExperienceEntryProps = {
			entry: theEntry,
			subtype: subtype,
			odd: index % 2 === 0,
		};
		const innerReturnValue = <ProfessionalExperienceEntry {...lineProps} />;
		return innerReturnValue;
	});
	return returnValue;
};
