import {useSelector} from "react-redux";
import {CompleteStore} from "../ReduxApp/reducers/reducerTypes";
import {getWindowWidth} from "../ReduxApp/app/consts/duck/selectors/ephemeral/getWindowWidth";
import {mobileThreshold} from "../Constants/layoutValues";

export const useMobile = () =>
{
	const returnValue = useSelector((state: CompleteStore) => getWindowWidth(state)) < mobileThreshold;
	return returnValue;
}
