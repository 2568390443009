import styled from 'styled-components';
import { cssVarColors } from '../../../Constants';
import { mobileThreshold } from '../../../Constants/layoutValues';

export const ContactInformationWrapper = styled.div`
	flex: 1;
	padding-left: 1.5rem;
	padding-bottom: 1.5rem;
	:first-of-type {
		padding-left: 0;
	}
	height: fit-content;
	max-height: fit-content;
	@media print {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
	@media (max-width: ${mobileThreshold}px) {
		padding-left: 0;
		padding-right: 0;
	}
	//padding-left: 1.5rem;
`;

export const ContactContentWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	margin-right: 1em;
	font-size: 0.9em;
	width: 100%;
	@media print {
		font-size: 0.9em !important;
	}
	@media (max-width: ${mobileThreshold}px) {
		margin-left: 0;
		margin-right: 0;
	}
`;

export const ContactInfoItemWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	padding-top: 0.25em;
	padding-bottom: 0.25em;
`;

export const ContactInfoIconWrapper = styled('div')`
	padding-right: 0.5rem;
`;

export const ContactInfoItemContentWrapper = styled('div')``;

export const ExternalLinkWrapper = styled('a')`
	color: ${cssVarColors.primaryLight};
	text-decoration: none;
`;

export const NestedAddressItem = styled('div')``;
