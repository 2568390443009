import { ConstDefaultStore } from './store';
import {ConstActionTypes} from './actionTypes';
import { ConstStoreType } from './storeTypes';

export const initialState = ConstDefaultStore;

export const ConstantsReducers = (state: ConstStoreType = initialState, action: ConstActionTypes): ConstStoreType => {
	switch (action.type) {
		case 'SET_WINDOW_WIDTH':
		{
			let newState =
				{
					...state,
					windowWidth: action.payload.width,
				}
			return newState;
		}
		case 'SET_WINDOW_HEIGHT':
		{
			let newState =
				{
					...state,
					windowHeight: action.payload.height,
				}
			return newState;
		}
		case 'SET_DEV_MODE':
		{
			let newState =
				{
					...state,
					devMode: action.payload.devModeOn,
				}
			return newState;
		}
		case 'SET_LAYOUT_TYPE':
		{
			let newState =
				{
					...state,
					layoutType: action.payload.layoutType,
				}
			return newState;
		}
		case 'SET_LOADING':
		{
			let newState =
				{
					...state,
					loading: action.payload.loading,
				}
			return newState;
		}
		case 'SET_BOOKING_MODE':
		{
			let newState =
				{
					...state,
					bookingMode: action.payload.bookingMode,
				}
			return newState;
		}
		default:
			return state;
	}
}
