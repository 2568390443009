import * as React from 'react';
import { IconWrapper, LabelWrapper, NavButtonWrapper, NavButtonWrapperRight } from './NavButtonStyles';
import { language } from './NavButtonLanguage';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/free-brands-svg-icons';

export type Props = {
	label: string;
	icon: string;
	href: string;
	alignRight?: boolean;
};

export const NavButtonLayout = (props: Props) => {
	const render = () => {
		const outgoingProps = {
			href: props.href,
		};
		const iconProps: FontAwesomeIconProps = {
			icon: ['fas', props.icon as IconName],
		};
		const leftContent = (
			<NavButtonWrapper {...outgoingProps}>
				<IconWrapper>
					<FontAwesomeIcon {...iconProps} />
				</IconWrapper>
				<LabelWrapper>{props.label}</LabelWrapper>
			</NavButtonWrapper>
		);
		const rightContent = (
			<NavButtonWrapperRight {...outgoingProps}>
				<LabelWrapper>{props.label}</LabelWrapper>
				<IconWrapper>
					<FontAwesomeIcon {...iconProps} />
				</IconWrapper>
			</NavButtonWrapperRight>
		);

		const returnValue = props.alignRight ? rightContent : leftContent;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
