import { UseCaseType } from '../../../Types/Portfolio/UseCaseType';
import { Chowbotics } from '../Employment/Chowbotics';

export const useCase: UseCaseType = {
	title: '#Canister Analysis',
	image: 'https://assets.dear-ux.com/faizhirani/images/Canister Estimation.png',
	employment: Chowbotics,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ['This use case illustrates a typical development cycle at Chowbotics.', 'As we approached product-market fit, we began serving a growing number of meals per day at more deployment sites, which highlighted an early issue: ingredient canisters frequently ran out. Initially, with low throughput, this wasn’t a significant problem. However, as throughput increased, running out of ingredients during the lunch rush became a major inconvenience for patrons, resulting in lost sales and customer churn.', 'Customer Success Managers began flagging the issue, prompting me to prioritize the development of the canister estimation feature I had already conceptualized.'],
		},
		{
			sectionTitle: 'Solution Implemented',
			paras: ['At its core, this feature was a mathematical service implemented within our distributed architecture. In theory, kitchen workers would weigh canisters during replacement and enter the weight into the system as part of the workflow. The amount of ingredient dispensed per meal was already tracked within the sales event broadcast by the Sallys, so it was simply a matter of applying the math and sending notifications to kitchen workers accordingly.', 'In practice, it was more complex. Instead of relying on kitchen workers to weigh canisters, an algorithm calculated the average weight and standard deviation of each canister-ingredient pair based on historical dispense data. The weights varied from site to site depending on how ingredients were packed. The algorithm also factored in average ingredient usage during rush hours, notifying kitchen workers when an ingredient was expected to run out during peak times.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['The feature had the desired impact: kitchen workers were notified when canister levels were low, allowing them to replace ingredients before running out.', 'Through iterative improvements, the feature evolved from individual canister notifications to a daily ledger per Sally. This ledger listed all remaining ingredients and recommended which canisters should be replaced'],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ['Chowbotics’ distributed, event-driven, and decoupled network infrastructure — which I had designed for agility — made it easy to create a service that maintained real-time details on every canister, segmented by SallyID and canister number.', 'The ease of spinning off this service also allowed us ample time to iterate on the front-end experience for kitchen workers, enabling collaboration with our design and branding teams to deliver a polished, well-integrated product.'],
		},
	],
};
