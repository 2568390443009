import * as React from 'react';
import { ContactInfoIconWrapper, ContactInfoItemContentWrapper, ContactInfoItemWrapper, ExternalLinkWrapper, NestedAddressItem } from '../ContactInformationStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type Props = {
	contactInfoLines: Array<any>;
};

export const generateContactInfo = (props: Props) => {
	const returnValue = props.contactInfoLines.map((line: any, index: number) => {
		const icon = <FontAwesomeIcon icon={line.icon} />;
		let content = null;
		if (line.href) {
			const linkProps = {
				href: line.href,
			};
			content = <ExternalLinkWrapper {...linkProps}>{line.text}</ExternalLinkWrapper>;
		} else if (!Array.isArray(line.text)) content = line.text;
		else {
			content = line.text.map((subLine: string) => {
				const nestedReturnValue = <NestedAddressItem>{subLine}</NestedAddressItem>;
				return nestedReturnValue;
			});
		}
		const innerReturnValue = (
			<ContactInfoItemWrapper>
				<ContactInfoIconWrapper>{icon}</ContactInfoIconWrapper>
				<ContactInfoItemContentWrapper>{content}</ContactInfoItemContentWrapper>
			</ContactInfoItemWrapper>
		);
		return innerReturnValue;
	});
	return returnValue;
};
