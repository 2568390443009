import { UseCaseType } from '../../../Types/Portfolio/UseCaseType';
import { TheHelperBees } from '../Employment/TheHelperBees';

export const useCase: UseCaseType = {
	title: '#Mobile App DX Restructure',
	// image: 'https://assets.dear-ux.com/faizhirani/images/Chowbotics Architecture.png',
	employment: TheHelperBees,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ["Although functional, the Helper Bees Mobile App's codebase was bogged down by technical debt. It allowed Personal Social Workers (Helpers) to log time, track their location, submit timesheets and claims, and communicate with the Operations team.", "The software team struggled to extend, repurpose, or refactor anything in the App. As a result, many features on the Product Roadmap were left untouched due to the team's reluctance to tackle them."],
		},
		{
			sectionTitle: 'Solution',
			paras: ['The Helper Bees brought me on as a contractor to lead a Developer Experience (DX) overhaul of the App. Given my advocacy for positive DX, I was entrusted with this project.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['The overhaul had the intended impact.', 'As a result, the App became far more approachable, enabling the software team to address bugs and issues in the field more efficiently.', 'Additionally, the team has resumed feature development from the previously stagnant Product Roadmap.'],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ['I was entrusted with this assignment primarily due to my demonstrated obsession with DX during my full-time employment.', 'As a team leader, I emphasized writing simple, consistent, and easy-to-consume code during one-on-one peer programming sessions, and code reviews with my team. I stressed the importance of repetition in organization.', 'For example, I insisted that all strings be abstracted to a language.ts file and that every layout have its own accompanying language.ts file, even if it contained only one string. By maintaining a predictable structure for files, folders, and functions, revisiting the code months later to add features required minimal cognitive load, as the context remained clear.', 'This approach was particularly effective for the redux modules, which my team had structured using the ducks paradigm.'],
		},
	],
};
