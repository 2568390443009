import { TechnologyType } from '../../../Types/TechnologyType';
import { ToolsType } from '../../../Types/ToolsType';

export type SchemaType = {
	title: string;
	lines: Array<ToolsType>;
};

export const schema: SchemaType = {
	title: 'Core Competence',
	lines: [
		{
			entry: 'Entrepreneurship',
		},
		{
			entry: 'Adaptability',
		},
		{
			entry: 'Collaboration',
		},
		{
			entry: 'Learning / Curiosity',
		},
		{
			entry: 'Teaching / Mentoring',
		},
		{
			entry: 'Ownership',
		},
		{
			entry: 'Articulation',
		},
		{
			entry: 'Clarity of Thought',
		},
		{
			entry: 'Critical Thinking',
		},
		{
			entry: 'Problem Structuring',
		},
		{
			entry: 'Empathy',
		},
		{
			entry: 'Unit Economics',
		},
	],
};
