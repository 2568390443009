import { FiltersActionTypes } from './actionTypes';
import { FiltersDefaultStore } from './store';
import { FilterStoreType } from './storeTypes';
import _ from 'lodash';

export const initialState = FiltersDefaultStore;

export const FiltersReducers = (state: FilterStoreType = initialState, action: FiltersActionTypes): FilterStoreType => {
	switch (action.type) {
		case 'SET_DATE_RANGE': {
			let newState = {
				...state,
				dateTime: action.payload.dateTime,
			};
			return newState;
		}
		default:
			return state;
	}
};
